import { IconType } from "react-icons/lib";
import { AiFillAudio, AiFillCreditCard } from "react-icons/ai";
import { BiCameraMovie, BiLink } from "react-icons/bi";
import { BsCardChecklist, BsFileText } from "react-icons/bs";
import { RiChatPollLine, RiFilePaper2Fill, RiMovieFill } from "react-icons/ri";
import { GrDocumentPdf } from "react-icons/gr";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { CompletionValidation } from "components/types/CompletionValidation";
import { Study } from "components/types/Study";
import { Classification } from "components/types/Classification";
import { destroyCookie, parseCookies, setCookie } from "nookies";

/**
 * 권한 사용 여부
 */
export const USE_ROLE = true;
export const USE_CLIENT_ROLE = true;

/**
 * 분류체계 최대 깊이
 */
export const CLASSIFICATION_DEPTH_LIMIT = 4;

/**
 * 사용자 기본권한
 */
export enum UserDefaultRole {
  ADMIN = "ADMIN", // 총괄관리자
  COMPANY_GROUP_MANAGER = "COMPANY_GROUP_MANAGER_ADMIN", // 주관처관리자
  COMPANY_MANAGER = "COMPANY_MANAGER_ADMIN", // 기관관리자
  CONTENT_PROVIDER = "CONTENT_PROVIDER_ADMIN", // CP사
  TEACHER = "TEACHER_ADMIN", // 강사
  TUTOR = "TUTOR_ADMIN", // 튜터
  USER = "USER", // 학습자
}
export const getUserDefaultRoleCodeName: any = {
  ADMIN: "총괄관리자",
  COMPANY_GROUP_MANAGER_ADMIN: "주관처관리자",
  COMPANY_MANAGER_ADMIN: "기관관리자",
  CONTENT_PROVIDER_ADMIN: "CP사",
  TEACHER_ADMIN: "강사",
  TUTOR_ADMIN: "튜터",
  USER: "학습자",
};

/**
 * 소속기관 변경 기본권한
 */
export enum EditCompanyDefaultRole {
  ADMIN = "ADMIN", // 총괄관리자
  COMPANY_GROUP_MANAGER_ = "COMPANY_GROUP_MANAGER_ADMIN", // 주관처관리자
  COMPANY_MANAGER = "COMPANY_MANAGER_ADMIN", // 기관관리자
  CONTENT_PROVIDER = "CONTENT_PROVIDER_ADMIN", // CP사
  TEACHER = "TEACHER_ADMIN", // 강사
  USER = "USER", // 학습자
}

/**
 * 통합검색 타입
 */
export enum TotalSearchType {
  ALL = "ALL", // 전체
  SUBJECT = "SUBJECT", // 과정
  CHANNEL = "CHANNEL", // 채널
  CHANNEL_CONTENT = "CHANNEL_CONTENT", // 채널 콘텐츠
  MEETING = "MEETING", // 포럼/세미나
}
export const getTotalSearchType: any = {
  ALL: "전체", // 전체
  SUBJECT: "과정", // 과정
  CHANNEL: "채널", // 채널
  CHANNEL_CONTENT: "채널 콘텐츠", // 채널 콘텐츠
  MEETING: "세미나/웨비나", // 세미나/웨비나
};

/**
 * 통합검색 범위
 */
export enum TotalSearchScope {
  ALL = "ALL", // 전체
  TITLE = "TITLE", // 제목
  CONTENT = "CONTENT", // 내용
  HASH_TAG = "HASH_TAG", // 해시태그(키워드)
}
export const getTotalSearchScope: any = {
  ALL: "전체", // 전체
  TITLE: "제목", // 제목
  CONTENT: "내용", // 내용
  HASH_TAG: "해시태그", // 해시태그(키워드)
};

/**
 * 통합검색 정렬
 */
export enum TotalSearchSorting {
  LATEST = "LATEST", // 최신순
  POPULAR = "POPULAR", // 인기순
}
export const getTotalSearchSorting: any = {
  LATEST: "최신순", // 최신순
  POPULAR: "인기순", // 인기순
};

/**
 * 시간표 슬롯
 */
export const TIMETABLE_SLOT = {
  DURATION: 10,
  MIN_TIME: "07:00",
  MAX_TIME: "21:00",
};

/**
 * 달력 버튼 유형
 */
export enum CALENDAR_BUTTON_TYPE {
  PREV_MONTH = "PREV_MONTH",
  NEXT_MONTH = "NEXT_MONTH",
  PREV_WEEK = "PREV_WEEK",
  NEXT_WEEK = "NEXT_WEEK",
}

/**
 * 하루
 */
export const ONE_DAY = 1000 * 60 * 60 * 24;

/**
 * 고정 시간표 일정
 */
export const STATIC_TIMETABLE_EVENT = [
  { id: "static01", title: "식사시간", duration: "1:00" },
  //{ id: "static02", title: "점심식사", duration: "1:00" },
  //{ id: "static03", title: "저녁식사", duration: "1:00" },
  { id: "static04", title: "쉬는시간", duration: "0:30" },
  { id: "static05", title: "조별활동", duration: "0:30" },
  { id: "static06", title: "원격교육 사전입장", duration: "0:30" },
];

/**
 * 선택 처리 유형
 */
export enum SELECTION_PROCESS_TYPE {
  ADD = "ADD",
  REDUCE = "REDUCE",
}

export const getExcelColumnCodeName: any = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
  9: "I",
  10: "J",
  11: "K",
  12: "L",
  13: "M",
  14: "N",
  15: "O",
  16: "P",
  17: "Q",
  18: "R",
  19: "S",
  20: "T",
  21: "U",
  22: "V",
  23: "W",
  24: "X",
  25: "Y",
  26: "Z",
};

/**
 * 회원구분
 */
export enum MemberType {
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
  TEACHER = "TEACHER",
  CONTENT_PROVIDER = "CONTENT_PROVIDER",
}
export const getMemberTypeCodeName: any = {
  PERSONAL: "일반",
  COMPANY: "기관",
  TEACHER: "강사",
  CONTENT_PROVIDER: "CP사",
};

/**
 * 업로드 유형
 */
export enum UploadType {
  FILE = "FILE",
  CDN = "CDN",
}

/**
 * CDN 유형
 */
export enum CdnType {
  CATENOID = "CATENOID",
  CATENOID_DRM = "CATENOID_DRM",
}
export const CDN_INFO = {
  CATENOID: {
    EXPIRE_TIME: 600, // 값의 범위는 0 < expire_time <= 21600 입니다. 빈값을 보내거나 항목 자체를 제거하면 기본 600초로 설정됩니다.
    CATEGORY_KEY: "8p4df21muz1sa81u", // 업로드한 파일이 속할 카테고리의 키(API를 이용하여 확득 가능)입니다. 빈값을 보내거나 항목 자체를 제거하면 '없음'에 속합니다.
    IS_ENCRYPTION_UPLOAD: 0, // 0은 일반 업로드, 1은 암호화 업로드입니다. 암호화 업로드시 파일이 암호화 되어 Kollus의 전용 플레이어로만 재생됩니다.
    IS_PASSTHROUGH: 0, // 1은 passthrough 업로드, 0은 일반 업로드 입니다.
    PROFILE_KEY: "", // is_passthrough가 1인 경우 의미가 있습니다. (profile_key={계정명}-pc-high) 설정->고급기능->인코딩 프로파일에서 확인가능합니다.
  },
  CATENOID_DRM: {
    EXPIRE_TIME: 600, // 값의 범위는 0 < expire_time <= 21600 입니다. 빈값을 보내거나 항목 자체를 제거하면 기본 600초로 설정됩니다.
    CATEGORY_KEY: "qurt93pcho4f8k1b", // 업로드한 파일이 속할 카테고리의 키(API를 이용하여 확득 가능)입니다. 빈값을 보내거나 항목 자체를 제거하면 '없음'에 속합니다.
    IS_ENCRYPTION_UPLOAD: 0, // 0은 일반 업로드, 1은 암호화 업로드입니다. 암호화 업로드시 파일이 암호화 되어 Kollus의 전용 플레이어로만 재생됩니다.
    IS_PASSTHROUGH: 0, // 1은 passthrough 업로드, 0은 일반 업로드 입니다.
    PROFILE_KEY: "", // is_passthrough가 1인 경우 의미가 있습니다. (profile_key={계정명}-pc-high) 설정->고급기능->인코딩 프로파일에서 확인가능합니다.
  },
};

/**
 * QR 유형
 */
export enum QrType {
  ENTRANCE = "ENTRANCE",
  EXIT = "EXIT",
}
export const getQrTypeCodeName: any = {
  ENTRANCE: "입실",
  EXIT: "퇴실",
};

// 학습 시청 기록 저장 주기
export const WATCH_INTERVAL = 10;

/**
 * 콘텐츠POOL 검색 기간유형
 * CONTENT_POOL_CREATED_DATE : 콘텐츠등록일
 */
export enum ContentPoolSearchPeriodType {
  CONTENT_POOL_CREATED_DATE = "CONTENT_POOL_CREATED_DATE",
  CONTENT_POOL_MODIFY_DATE = "CONTENT_POOL_MODIFY_DATE",
}
export const getContentPoolSearchPeriodTypeCodeName: any = {
  CONTENT_POOL_CREATED_DATE: "콘텐츠등록일",
  CONTENT_POOL_MODIFY_DATE: "최종수정일",
};

/**
 * 과정 검색 기간유형
 * SEQUENCE_APPLICATION_START_DATE : 신청시작일
 * SEQUENCE_APPLICATION_END_DATE   : 신청종료일
 * SEQUENCE_STUDY_START_DATE       : 학습시작일
 * SEQUENCE_STUDY_END_DATE         : 학습종료일
 */
export enum SubjectSearchPeriodType {
  SEQUENCE_APPLICATION_START_DATE = "SEQUENCE_APPLICATION_START_DATE",
  SEQUENCE_APPLICATION_END_DATE = "SEQUENCE_APPLICATION_END_DATE",
  SEQUENCE_STUDY_START_DATE = "SEQUENCE_STUDY_START_DATE",
  SEQUENCE_STUDY_END_DATE = "SEQUENCE_STUDY_END_DATE",
}
export const getSubjectSearchPeriodTypeCodeName: any = {
  SEQUENCE_APPLICATION_START_DATE: "신청시작일",
  SEQUENCE_APPLICATION_END_DATE: "신청종료일",
  SEQUENCE_STUDY_START_DATE: "학습시작일",
  SEQUENCE_STUDY_END_DATE: "학습종료일",
};

/**
 * 수강 검색 기간유형
 * START_DATE       : 시작일
 * END_DATE         : 종료일
 */
export enum RegistrationSearchPeriodType {
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
}
export const getRegistrationSearchPeriodTypeCodeName: any = {
  START_DATE: "시작일",
  END_DATE: "종료일",
};

/**
 * 채널 검색 기간유형
 * CHANNEL_ACTIVITY_START_DATE : 활동시작일
 * CHANNEL_ACTIVITY_END_DATE   : 활동종료일
 */
export enum ChannelSearchPeriodType {
  CHANNEL_ACTIVITY_START_DATE = "CHANNEL_ACTIVITY_START_DATE",
  CHANNEL_ACTIVITY_END_DATE = "CHANNEL_ACTIVITY_END_DATE",
}
export const getChannelSearchPeriodTypeCodeName: any = {
  CHANNEL_ACTIVITY_START_DATE: "활동시작일",
  CHANNEL_ACTIVITY_END_DATE: "활동종료일",
};

/**
 * 채널콘텐츠 검색 기간유형
 * CHANNEL_CONTENT_CREATED_START_DATE : 등록시작일
 * CHANNEL_CONTENT_CREATED_END_DATE   : 등록종료일
 */
export enum ChannelContentSearchPeriodType {
  CHANNEL_CONTENT_CREATED_START_DATE = "CHANNEL_CONTENT_CREATED_START_DATE",
  CHANNEL_CONTENT_CREATED_END_DATE = "CHANNEL_CONTENT_CREATED_END_DATE",
}
export const getChannelContentSearchPeriodTypeCodeName: any = {
  CHANNEL_CONTENT_CREATED_START_DATE: "등록시작일",
  CHANNEL_CONTENT_CREATED_END_DATE: "등록종료일",
};

/**
 * 수강의뢰서 검색 기간유형
 * LECTURE_START_DATE       : 강의시작일
 * LECTURE_END_DATE         : 강의종료일
 */
export enum LectureRequestSearchPeriodType {
  LECTURE_START_DATE = "LECTURE_START_DATE",
  LECTURE_END_DATE = "LECTURE_END_DATE",
}
export const getLectureRequestSearchPeriodTypeCodeName: any = {
  LECTURE_START_DATE: "강의시작일",
  LECTURE_END_DATE: "강의종료일",
};

/**
 * 수강의뢰서 교육방법
 */
export enum LectureRequestEducationMethod {
  LECTURE = "LECTURE",
  EXAMPLE = "EXAMPLE",
  TRAINING = "TRAINING",
  DISCUSSION = "DISCUSSION",
  EXCURSIONS = "EXCURSIONS",
  ETC = "ETC",
}
export const getLectureRequestEducationMethodCodeName: any = {
  LECTURE: "강의",
  EXAMPLE: "사례",
  TRAINING: "실습",
  DISCUSSION: "토의",
  EXCURSIONS: "견학",
  ETC: "기타",
};

/**
 * 수강의뢰서 상태
 */
export enum LectureRequestStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
export const getLectureRequestStatusCodeName: any = {
  REQUESTED: "의뢰",
  APPROVED: "승인",
  REJECTED: "반려",
};

/**
 * 오류신고 대상 유형
 */
export enum ErrorReportTargetType {
  SUBJECT = "SUBJECT",
  CONTENT_POOL = "CONTENT_POOL",
  CHANNEL_CONTENT = "CHANNEL_CONTENT",
}
export const getErrorReportTargetTypeCodeName: any = {
  SUBJECT: "과정",
  CONTENT_POOL: "콘텐츠",
  CHANNEL_CONTENT: "채널 콘텐츠",
};

/**
 * 오류신고 상태
 */
export enum ErrorReportStatus {
  REQUESTED = "REQUESTED",
  PROCESSED = "PROCESSED",
}
export const getErrorReportStatusCodeName: any = {
  REQUESTED: "접수",
  PROCESSED: "완료",
};

/**
 * 조회 대상 유형
 */
export enum ViewTargetType {
  SUBJECT = "SUBJECT",
  CONTENT_POOL = "CONTENT_POOL",
  CHANNEL_CONTENT = "CHANNEL_CONTENT",
}
export const getViewTargetTypeCodeName: any = {
  SUBJECT: "과정",
  CONTENT_POOL: "콘텐츠 POOL",
  CHANNEL_CONTENT: "채널 콘텐츠",
};

/**
 * 좋아요 대상 유형
 */
export enum FavoriteTargetType {
  SUBJECT = "SUBJECT",
  CONTENT_POOL = "CONTENT_POOL",
  CHANNEL_CONTENT = "CHANNEL_CONTENT",
  CHANNEL_PLAY_LIST = "CHANNEL_PLAY_LIST",
  MEETING_FORUM = "MEETING_FORUM",
  ARTICLE = "ARTICLE",
}
export const getFavoriteTargetTypeCodeName: any = {
  SUBJECT: "과정",
  CONTENT_POOL: "콘텐츠 POOL",
  CHANNEL_CONTENT: "채널 콘텐츠",
  MEETING_FORUM: "포럼",
};

/**
 * 콘텐츠POOL 사용용도
 */
export enum ContentPoolPurpose {
  SUBJECT = "SUBJECT",
  ONLINE_CONTENT = "ONLINE_CONTENT",
  CHANNEL = "CHANNEL",
}
export const getContentPoolPurposeCodeName: any = {
  SUBJECT: "과정",
  ONLINE_CONTENT: "온라인콘텐츠",
  CHANNEL: "채널",
};

/**
 * 콘텐츠POOL 유형
 */
export enum ContentPoolType {
  THREAD = "THREAD",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  CARD = "CARD",
  WEB_VIDEO = "WEB_VIDEO",
  LINK = "LINK",
  PDF = "PDF",
  TEXT = "TEXT",
  SEMINAR = "SEMINAR",
  EXAM_PAPER = "EXAM_PAPER",
  REPORT = "REPORT",
  SURVEY = "SURVEY",
  // OUT_SUBJECT = "OUT_SUBJECT",
}
export const getContentPoolTypeCodeName: any = {
  THREAD: "과목",
  VIDEO: "동영상",
  AUDIO: "오디오",
  CARD: "카드러닝",
  WEB_VIDEO: "웹영상",
  LINK: "링크",
  PDF: "PDF",
  TEXT: "텍스트",
  SEMINAR: "세미나",
  EXAM_PAPER: "시험",
  REPORT: "과제",
  SURVEY: "설문",
};
export const getContentPoolTypeCodeInfo: any = {
  1: {
    enumCode: "THREAD",
    name: "과목",
    path: "thread",
    Icon: AiFillCreditCard,
    show: true,
    channel: false,
  },
  2: {
    enumCode: "VIDEO",
    name: "동영상",
    path: "video",
    Icon: BiCameraMovie,
    show: true,
    channel: true,
  },
  3: {
    enumCode: "AUDIO",
    name: "오디오",
    path: "audio",
    Icon: AiFillAudio,
    show: true,
    channel: true,
  },
  4: {
    enumCode: "CARD",
    name: "카드러닝",
    path: "card",
    Icon: BsCardChecklist,
    show: true,
    channel: true,
  },
  5: {
    enumCode: "WEB_VIDEO",
    name: "웹영상",
    path: "webVideo",
    Icon: RiMovieFill,
    show: true,
    channel: true,
  },
  6: {
    enumCode: "LINK",
    name: "링크",
    path: "link",
    Icon: BiLink,
    show: true,
    channel: true,
  },
  7: {
    enumCode: "PDF",
    name: "PDF",
    path: "pdf",
    Icon: GrDocumentPdf,
    show: true,
    channel: true,
  },
  8: {
    enumCode: "TEXT",
    name: "텍스트",
    path: "text",
    Icon: BsFileText,
    show: true,
    channel: true,
  },
  9: {
    enumCode: "SEMINAR",
    name: "세미나",
    path: "seminar",
    Icon: FaChalkboardTeacher,
    show: false,
    channel: false,
  },
  10: {
    enumCode: "EXAM_PAPER",
    name: "시험",
    path: "examPaper",
    Icon: RiFilePaper2Fill,
    show: true,
    channel: false,
  },
  11: {
    enumCode: "REPORT",
    name: "과제",
    path: "report",
    Icon: MdWork,
    show: true,
    channel: false,
  },
  12: {
    enumCode: "SURVEY",
    name: "설문",
    path: "surveyPaper",
    Icon: RiChatPollLine,
    show: true,
    channel: false,
  },
  // 13: {
  //     enumCode: "OUT_SUBJECT",
  //     name: "외부과정",
  //     path: 'outSubject',
  //     Icon: RiChatPollLine,
  //     show: false,
  //     channel: false,
  // },
};

/**
 * 콘텐츠POOL 콘텐츠타입
 */
export const getContentPoolContentTypeCodeName: any = {
  1: "파일",
  8: "CDN(카테노이드)",
};

/**
 * 과정 유형
 */
export const getSubjectTypeCodeName: any = {
  1: "집합교육",
  2: "실시간원격교육",
  3: "온라인교육",
  4: "조합교육",
  5: "하이플렉스교육",
  // 6: "마이크로러닝",
};

export const getSubjectTypeStudentCodeName: any = {
  1: "집합교육",
  2: "실시간원격교육",
  3: "온라인교육",
  4: "조합교육",
  5: "하이플렉스교육",
  // 6: "마이크로러닝",
};

export const getSubjectTypeCodeNum: any = {
  "": [],
  집합교육: [1],
  실시간원격교육: [2],
  온라인교육: [3, 4],
  혼합교육: [5],
};

/**
 * 과정 유형
 */
export const getSubjectTypeCodeNames: any = {
  1: "offline",
  2: "realtime",
  3: "online",
  4: "mixed",
  5: "blended",
  6: "micro",
};

/**
 * 과정 상세유형
 */
export const getSubjectTypeDetailCodeName: any = {
  1: "집합교육",
  2: "실시간원격교육",
  3: "온라인교육(정기)",
  4: "온라인교육(상시)",
  5: "조합교육(정기)",
  6: "조합교육(상시)",
  7: "혼합교육",
};

/**
 * 승인여부
 */
export const getStatusCodeName: any = {
  1: "신청",
  2: "승인",
  3: "반려",
};

/**
 * 학습방법
 */
export const getStudyWayCodeName: any = {
  1: "비순차",
  2: "순차",
  3: "선택학습",
};

/**
 * 결제방법
 */
export enum PaymentMethod {
  VIRTUAL_ACCOUNT = "VIRTUAL_ACCOUNT",
  CREDIT_CARD = "CREDIT_CARD",
  TAX_BILL = "TAX_BILL",
  NOT_DECIDED = "NOT_DECIDED",
}
export const getPaymentMethodCodeName: any = {
  VIRTUAL_ACCOUNT: {
    admin: "가상계좌",
    client: "가상계좌(무통장입금)",
  },
  CREDIT_CARD: {
    admin: "신용카드",
    client: "카드",
  },
  TAX_BILL: {
    admin: "전자계산서",
    client: "전자계산서",
  },
  NOT_DECIDED: {
    admin: "-",
    client: "-",
  },
};

/**
 * 분류체계구분
 */
export enum ClassificationType {
  SUBJECT = "SUBJECT",
  CONTENT_POOL = "CONTENT_POOL",
  INTEREST = "INTEREST",
  ABILITY = "ABILITY",
  KIRD_SPECIAL = "KIRD_SPECIAL",
}
export const getClassificationTypeCodeName: any = {
  SUBJECT: "과정분류",
  CONTENT_POOL: "콘텐츠분류",
  INTEREST: "학습자관심분야",
  ABILITY: "역량분류",
  KIRD_SPECIAL: "KIRD스페셜",
};

/**
 * 분류체계내용 언어
 */
export enum ClassificationContentLanguage {
  KO = "KO",
  EN = "EN",
}
export const getClassificationContentLanguageCodeName: any = {
  KO: "국어",
  EN: "영어",
};

/**
 * 분류체계 path (leaf to root)
 * @param classification {
 *   id: ID
 *   name: string
 *   parent: classification | null
 * }
 * @param result classification path string
 */
export const getClassificationPath = (
  classification: Classification,
  result: string | null = null
): string =>
  classification?.parent
    ? getClassificationPath(
        classification.parent,
        [classification.name, result].filter((i) => !!i).join(" > ")
      )
    : classification?.name
    ? [classification.name, result].filter((i) => !!i).join(" > ")
    : "";

/**
 * 학습 안내문 구분
 */
export const getStudyGuideTypeCodeName: any = {
  1: "텍스트",
  2: "이미지",
};

/**
 * 시험구분
 */
export enum ExamPaperType {
  BEFORE = "BEFORE",
  MAIN = "MAIN",
  AFTER = "AFTER",
}
export const getExamPaperTypeCodeName: any = {
  BEFORE: "사전",
  MAIN: "본",
  AFTER: "사후",
};

/**
 * 시험응시 상태
 */
export enum ExamStatus {
  TAKEN = "TAKEN",
  SUBMITTED = "SUBMITTED",
  MARKED = "MARKED",
}
export const getExamStatusCodeName: any = {
  TAKEN: "응시함",
  SUBMITTED: "제출함",
  MARKED: "채점함",
};

/**
 * 과제구분
 */
export enum ReportType {
  BEFORE = "BEFORE",
  MAIN = "MAIN",
  AFTER = "AFTER",
}
export const getReportTypeCodeName: any = {
  BEFORE: "사전",
  MAIN: "본",
  AFTER: "사후",
};

/**
 * 과제 제출 상태
 */
export enum ReportSubmissionStatus {
  TEMPORARY_SAVED = "TEMPORARY_SAVED",
  SUBMITTED = "SUBMITTED",
  MARKED = "MARKED",
  REJECTED = "REJECTED",
}
export const getReportSubmissionStatusCodeName: any = {
  TEMPORARY_SAVED: "임시저장",
  SUBMITTED: "제출함",
  MARKED: "채점됨",
  REJECTED: "반려됨",
};

/**
 * 문제유형
 */
export enum ExamQuestionType {
  SHORT_ANSWER = "SHORT_ANSWER",
  OBJECTIVE = "OBJECTIVE",
  OX_QUIZ = "OX_QUIZ",
  SUBJECTIVE = "SUBJECTIVE",
}
export const getExamQuestionTypeCodeName: any = {
  SHORT_ANSWER: "단답형",
  OBJECTIVE: "객관식",
  OX_QUIZ: "OX",
  SUBJECTIVE: "서술형",
};

/**
 * OX 퀴즈 보기
 */
export enum OxQuizChoice {
  O = "O",
  X = "X",
}

/**
 * 강의 방식
 */
export enum ClassroomType {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
}
export const getClassroomTypeCodeName: any = {
  OFFLINE: "집합교육",
  ONLINE: "실시간원격교육",
};

/**
 * 수료기준 구분
 */
export enum CompletionStandardType {
  TOTAL_SCORE = "TOTAL_SCORE", // 총점
  ATTENDANCE_RATE = "ATTENDANCE_RATE", // 출석율
  PROGRESS_RATE = "PROGRESS_RATE", // 진도율
  PER_SEQUENCE_LESSON = "PER_SEQUENCE_LESSON", // 차수 차시별
  PER_CONTENT_TYPE = "PER_CONTENT_TYPE", // 차시 유형별
}
export const getCompletionStandardTypeCodeName: any = {
  TOTAL_SCORE: "총점",
  ATTENDANCE_RATE: "출석율",
  PROGRESS_RATE: "진도율",
  PER_SEQUENCE_LESSON: "차수 차시별",
  PER_CONTENT_TYPE: "차시 유형별",
};

/**
 *  차수 수료기준 구분
 */
export enum SequenceCompletionStandardType {
  totalScore = "TOTAL_SCORE", // 총점
  attendanceRate = "ATTENDANCE_RATE", // 출석율
  examPaperRate = "EXAM_PAPER_RATE",
  reportRate = "REPORT_RATE",
  surveyPaperRate = "SURVEY_PAPER_RATE",
}
export const getSequenceCompletionStandardTypeCodeName: any = {
  TOTAL_SCORE: "총점",
  ATTENDANCE_RATE: "출석율",
  EXAM_PAPER_RATE: "시험",
  REPORT_RATE: "과제",
  SURVEY_PAPER_RATE: "설문",
};

/**
 * 수료검증 정보
 */
export const getCompletionValidationInfo = (
  type: CompletionStandardType | null,
  completionValidations: Array<CompletionValidation> | null
) => {
  if (!type || !completionValidations) return null;

  const newCompletionValidations = completionValidations?.filter(
    (completionValidation: CompletionValidation) =>
      completionValidation?.completionStandard?.type === type
  );
  return newCompletionValidations?.length > 0
    ? newCompletionValidations[0]
    : null;
};

/**
 * 최근 학습 정보
 */
export const getLastStudyByRegistrationId = (
  studies: Array<Study> | undefined,
  registrationId?: string
) => {
  if (!studies || studies === undefined) return null;
  const newStudies =
    studies?.length > 0
      ? [...studies]
          ?.sort((a: Study, b: Study) =>
            a.lastModifiedDateTime > b.lastModifiedDateTime ? 1 : -1
          )
          ?.filter((myStudy: Study) =>
            registrationId ? myStudy.registration?.id === registrationId : true
          )
      : null;
  return newStudies && newStudies?.length > 0 ? newStudies[0] : null;
};
export const getLastStudyBySequenceLessonId = (
  studies: Array<Study> | undefined,
  sequenceLessonId?: string
) => {
  if (!studies || studies === undefined) return null;
  const newStudies =
    studies?.length > 0
      ? [...studies]
          ?.sort((a: Study, b: Study) =>
            a.lastModifiedDateTime > b.lastModifiedDateTime ? 1 : -1
          )
          ?.filter((study: Study) =>
            sequenceLessonId
              ? study.sequenceLesson?.id === sequenceLessonId
              : true
          )
      : null;
  return newStudies && newStudies?.length > 0 ? newStudies[0] : null;
};

/**
 * 설문지 유형
 */
export enum SurveyPaperType {
  GENERAL = "GENERAL",
  SATISFACTION = "SATISFACTION",
  LIVE_POLL = "LIVE_POLL",
  LIVE_SURVEY = "LIVE_SURVEY",
}
export const getSurveyPaperTypeCodeName: any = {
  GENERAL: "일반설문",
  SATISFACTION: "만족도조사",
  LIVE_POLL: "Live Poll",
  LIVE_SURVEY: "Live Survey",
};

/**
 * 설문지 위치 구분
 */
export enum SurveyPaperPositionType {
  BEFORE = "BEFORE",
  MAIN = "MAIN",
  AFTER = "AFTER",
}
export const getSurveyPaperPositionTypeCodeName: any = {
  BEFORE: "사전",
  MAIN: "본",
  AFTER: "사후",
};

/**
 * 문항유형
 */
export enum SurveyQuestionType {
  INFORMATION = "INFORMATION",
  SCALE = "SCALE",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  SUBJECTIVE = "SUBJECTIVE",
  BLENDED = "BLENDED",
  PER_TEACHER = "PER_TEACHER",
  PER_THREAD = "PER_THREAD",
  PER_THREAD_BLENDED = "PER_THREAD_BLENDED",
}
export const getSurveyQuestionTypeCodeName: any = {
  INFORMATION: "안내문",
  SCALE: "척도",
  SINGLE_CHOICE: "단일선택",
  MULTIPLE_CHOICE: "복수선택",
  SUBJECTIVE: "주관식",
  BLENDED: "혼합형",
  PER_TEACHER: "강사별만족도",
  PER_THREAD: "과목별만족도",
  PER_THREAD_BLENDED: "과목별만족도(혼합형)",
};

/**
 * 응답유형
 */
export enum SurveyAnswerType {
  SCALE = "SCALE",
  OBJECTIVE = "OBJECTIVE",
  SUBJECTIVE = "SUBJECTIVE",
  BLENDED = "BLENDED",
  PER_TEACHER = "PER_TEACHER",
  PER_THREAD = "PER_THREAD",
  PER_THREAD_BLENDED = "PER_THREAD_BLENDED",
}
export const getSurveyAnswerTypeCodeName: any = {
  SCALE: "척도",
  OBJECTIVE: "객관식",
  SUBJECTIVE: "주관식",
  BLENDED: "혼합형",
  PER_TEACHER: "강사별만족도",
  PER_THREAD: "과목별만족도",
  PER_THREAD_BLENDED: "과목별만족도(혼합형)",
};

/**
 * 설문분류
 */
export enum SurveyQuestionCategory {
  EFFECT = "EFFECT",
  CONTENTS = "CONTENTS",
  METHOD = "METHOD",
  TEACHER = "TEACHER",
  THREAD = "THREAD",
  FACILITY = "FACILITY",
  SYSTEM = "SYSTEM",
  OPERATION = "OPERATION",
  EDUCATION = "EDUCATION",
  ETC = "ETC",
}
export const getSurveyQuestionCategoryFirstCodeName: any = {
  OFFLINE: "집합",
  REALTIME: "실시간",
  ONLINE: "온라인/조합",
  BLENDED: "혼합",
};
export const getSurveyQuestionCategoryThirdCodeName: any = {
  PREPARATORY_LEARNING: "사전학습",
  CLASSROOM: "강의실",
  EMPLOYEE_COURTESY: "직원친절도",
  ACCOMMODATION: "숙식",
  STABILITY: "안정성",
  CONVENIENCE: "편리성",
  OPERATIONAL_APPROPRIATENESS: "운영적절성",
  LECTURE_EXPERTISE: "강의전문성",
  TEACHING_SKILLS: "강의기술",
  TEACHING_ATTITUDE: "강의태도",
};
export const getSurveyQuestionCategoryCodeName: any = {
  CONTENTS: "내용",
  SYSTEM: "시스템",
  OPERATION: "운영",
  EDUCATION: "교육",
  ETC: "기타",
  POPULATION_STATISTICS: "인구통계",
  EDUCATIONAL_EFFECTIVENESS: "교육효과",
  CURRICULUM_PLANNING: "교과편성",
  EDUCATIONAL_OPERATION: "교육운영",
  EDUCATIONAL_PROGRAM: "교육과정",
  EDUCATIONAL_ENVIRONMENT_OFFLINE: "교육환경(오프라인)",
  EDUCATIONAL_ENVIRONMENT_ONLINE: "교육환경(실시간온라인)",
  BY_SUBJECT: "과목별",
  BY_INSTRUCTOR: "강사별",
  ETC_OFFLINE: "기타(오프라인)",
};

export const getSurveyQuestionMultipleCategoryCodeName: any = [
  {
    label: "집합",
    value: "OFFLINE",
    category: [
      {
        label: "인구 통계",
        value: "POPULATION_STATISTICS",
        category: [],
      },
      {
        label: "교육 효과",
        value: "EDUCATIONAL_EFFECTIVENESS",
        category: [],
      },
      {
        label: "교과 편성",
        value: "CURRICULUM_PLANNING",
        category: [],
      },
      {
        label: "교육 운영",
        value: "EDUCATIONAL_OPERATION",
        category: [],
      },
      {
        label: "교육 과정",
        value: "EDUCATIONAL_PROGRAM",
        category: [
          {
            label: "사전 학습",
            value: "PREPARATORY_LEARNING",
          },
        ],
      },
      {
        label: "교육 환경(오프라인)",
        value: "EDUCATIONAL_ENVIRONMENT_OFFLINE",
        category: [
          {
            label: "강의실",
            value: "CLASSROOM",
          },
          {
            label: "직원친절도",
            value: "EMPLOYEE_COURTESY",
          },
          {
            label: "숙식",
            value: "ACCOMMODATION",
          },
        ],
      },
      {
        label: "기타(오프라인)",
        value: "ETC_OFFLINE",
        category: [],
      },
      {
        label: "과목별(과목별만족도 선택시)",
        value: "BY_SUBJECT",
        category: [],
      },
      {
        label: "강사별(강사별만족도 선택시)",
        value: "BY_INSTRUCTOR",
        category: [
          {
            label: "강의전문성",
            value: "LECTURE_EXPERTISE",
          },
          {
            label: "강의기술",
            value: "TEACHING_SKILLS",
          },
          {
            label: "강의태도",
            value: "TEACHING_ATTITUDE",
          },
        ],
      },
    ],
  },
  {
    label: "실시간",
    value: "REALTIME",
    category: [
      {
        label: "인구 통계",
        value: "POPULATION_STATISTICS",
        category: [],
      },
      {
        label: "교육 효과",
        value: "EDUCATIONAL_EFFECTIVENESS",
        category: [],
      },
      {
        label: "교과 편성",
        value: "CURRICULUM_PLANNING",
        category: [],
      },
      {
        label: "교육 운영",
        value: "EDUCATIONAL_OPERATION",
        category: [],
      },
      {
        label: "교육 과정",
        value: "EDUCATIONAL_PROGRAM",
        category: [
          {
            label: "사전 학습",
            value: "PREPARATORY_LEARNING",
          },
        ],
      },
      {
        label: "교육 환경(실시간온라인)",
        value: "EDUCATIONAL_ENVIRONMENT_ONLINE",
        category: [
          {
            label: "안정성",
            value: "STABILITY",
          },
          {
            label: "편리성",
            value: "CONVENIENCE",
          },
          {
            label: "운영적절성",
            value: "OPERATIONAL_APPROPRIATENESS",
          },
        ],
      },
      {
        label: "기타(오프라인)",
        value: "ETC_OFFLINE",
        category: [],
      },
      {
        label: "과목별(과목별만족도 선택시)",
        value: "BY_SUBJECT",
        category: [],
      },
      {
        label: "강사별(강사별만족도 선택시)",
        value: "BY_INSTRUCTOR",
        category: [
          {
            label: "강의전문성",
            value: "LECTURE_EXPERTISE",
          },
          {
            label: "강의기술",
            value: "TEACHING_SKILLS",
          },
          {
            label: "강의태도",
            value: "TEACHING_ATTITUDE",
          },
        ],
      },
    ],
  },
  {
    label: "하이플렉스교육",
    value: "BLENDED",
    category: [
      {
        label: "인구 통계",
        value: "POPULATION_STATISTICS",
        category: [],
      },
      {
        label: "교육 효과",
        value: "EDUCATIONAL_EFFECTIVENESS",
        category: [],
      },
      {
        label: "교과 편성",
        value: "CURRICULUM_PLANNING",
        category: [],
      },
      {
        label: "교육 운영",
        value: "EDUCATIONAL_OPERATION",
        category: [],
      },
      {
        label: "교육 과정",
        value: "EDUCATIONAL_PROGRAM",
        category: [
          {
            label: "사전 학습",
            value: "PREPARATORY_LEARNING",
          },
        ],
      },
      {
        label: "교육 환경(오프라인)",
        value: "EDUCATIONAL_ENVIRONMENT_OFFLINE",
        category: [
          {
            label: "강의실",
            value: "CLASSROOM",
          },
          {
            label: "직원친절도",
            value: "EMPLOYEE_COURTESY",
          },
          {
            label: "숙식",
            value: "ACCOMMODATION",
          },
        ],
      },
      {
        label: "교육 환경(실시간온라인)",
        value: "EDUCATIONAL_ENVIRONMENT_ONLINE",
        category: [
          {
            label: "안정성",
            value: "STABILITY",
          },
          {
            label: "편리성",
            value: "CONVENIENCE",
          },
          {
            label: "운영적절성",
            value: "OPERATIONAL_APPROPRIATENESS",
          },
        ],
      },
      {
        label: "기타(오프라인)",
        value: "ETC_OFFLINE",
        category: [],
      },
      {
        label: "과목별(과목별만족도 선택시)",
        value: "BY_SUBJECT",
        category: [],
      },
      {
        label: "강사별(강사별만족도 선택시)",
        value: "BY_INSTRUCTOR",
        category: [
          {
            label: "강의전문성",
            value: "LECTURE_EXPERTISE",
          },
          {
            label: "강의기술",
            value: "TEACHING_SKILLS",
          },
          {
            label: "강의태도",
            value: "TEACHING_ATTITUDE",
          },
        ],
      },
    ],
  },
  {
    label: "온라인/조합",
    value: "ONLINE",
    category: [
      {
        label: "내용",
        value: "CONTENTS",
        category: [],
      },
      {
        label: "시스템",
        value: "SYSTEM",
        category: [],
      },
      {
        label: "운영",
        value: "OPERATION",
        category: [],
      },
      {
        label: "교육",
        value: "EDUCATION",
        category: [],
      },
      {
        label: "기타",
        value: "ETC",
        category: [],
      },
    ],
  },
];

/**
 * 척도유형
 */
export enum SurveyScaleType {
  FIVE = "FIVE",
  SEVEN = "SEVEN",
}
export const getSurveyScaleTypeCodeName: any = {
  FIVE: {
    name: "5점척도",
    count: 5,
  },
  SEVEN: {
    name: "7점척도",
    count: 7,
  },
};

/**
 * 콘텐츠유형
 */
export enum OnlineContentMethod {
  PORTING = "PORTING",
  // LINK = "LINK",
  // TODO: hlee - 링크를 사용할 경우 학습자 화면에서 연결하는 로직 개발 필요
}
export const getOnlineContentMethodCodeName: any = {
  PORTING: "포팅",
  // LINK: "링크(강의실)",
};

/**
 * 업로드유형
 */
export enum FileUploadType {
  ZIP = "ZIP",
  FOLDER = "FOLDER",
}
export const getFileUploadTypeCodeName: any = {
  ZIP: "ZIP파일",
  FOLDER: "폴더",
};

/**
 * 차시 항목 구분
 */
export enum LessonType {
  INDEX = "INDEX",
  STUDY = "STUDY",
  ETC = "ETC",
}
export const getLessonTypeCodeName: any = {
  INDEX: "목차",
  STUDY: "학습",
  ETC: "기타",
};

/**
 * 온라인콘텐츠 링크(강의실) 파라미터 치환자
 */
export const getOnlineContentLinkReservedWordCodeName: any = {
  "학습자 ID": "{{userId}}",
  학습자명: "{{userName}}",
  "회사 ID": "{{companyId}}",
  회사명: "{{companyName}}",
  "과정 ID": "{{subjectId}}",
  "차수 ID": "{{sequenceId}}",
};

/**
 * 채널 콘텐츠 상태
 */
export enum ChannelContentStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
export const getChannelContentStatusCodeName: any = {
  REQUESTED: "신청(승인대기)",
  APPROVED: "승인",
  REJECTED: "반려",
};

/**
 * 입과상태
 */
export enum RegistrationStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  WAITING = "WAITING",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
  NOT_COMPLETED = "NOT_COMPLETED",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  CANCEL_REQUESTED = "CANCEL_REQUESTED",
  // COMPLETED_PAYMENT = "COMPLETED_PAYMENT",
}
export const getRegistrationStatusCodeName: any = {
  REQUESTED: "신청",
  APPROVED: "승인",
  WAITING: "대기",
  CANCELLED: "취소",
  REJECTED: "반려",
  COMPLETED: "수료",
  NOT_COMPLETED: "미수료",
  WAITING_PAYMENT: "결제대기",
  CANCEL_REQUESTED: "취소요청",
  // COMPLETED_PAYMENT: "결제완료",
};

/**
 * 납부상태
 */
export enum PaymentStatus {
  FULL_PAYMENT = "FULL_PAYMENT",
  NON_PAYMENT = "NON_PAYMENT",
  REFUND = "REFUND",
  COMPLETE_PAYMENT = "COMPLETE_PAYMENT",
  NOT_DECIDED = "NOT_DECIDED",
}
export const getPaymentStatusCodeName: any = {
  FULL_PAYMENT: "완납",
  NON_PAYMENT: "미납",
  REFUND: "환불",
  COMPLETE_PAYMENT: "결제완료",
  NOT_DECIDED: "-",
};

/**
 * 메세지 발송 유형
 */
export enum SendMessageType {
  EMAIL = "EMAIL",
  // KAKAOTALK = "KAKAOTALK",
  SMS = "SMS",
  PUSH = "PUSH",
}
export const getSendMessageTypeCodeName: any = {
  EMAIL: "메일",
  // KAKAOTALK: "알림톡",
  SMS: "SMS",
  PUSH: "PUSH",
};

/**
 * 스케쥴러 메세지 발송 유형(임시)
 */
export enum SchedulerSendMessageType {
  EMAIL = "EMAIL",
  KAKAOTALK = "KAKAOTALK",
  SMS = "SMS",
  PUSH = "PUSH",
}
export const getSchedulerSendMessageTypeCodeName: any = {
  EMAIL: "메일",
  KAKAOTALK: "알림톡",
  SMS: "SMS",
  PUSH: "PUSH",
};

/**
 * 메세지 발송 상태
 */
export enum SendMessageStatus {
  SEND_SUCCESS = "SEND_SUCCESS",
  SEND_FAILURE = "SEND_FAILURE",
}
export const getSendMessageStatusCodeName: any = {
  SEND_SUCCESS: "발송성공",
  SEND_FAILURE: "발송실패",
};

/**
 * 출결 상태
 */
export enum AttendanceStatus {
  CREATED = "CREATED",
  ENTERED = "ENTERED",
  EXITED = "EXITED",
  OUTING = "OUTING",
  BACKED = "BACKED",
}
export const getAttendanceStatusCodeName: any = {
  CREATED: "생성",
  ENTERED: "입실",
  EXITED: "퇴실",
  OUTING: "외출",
  BACKED: "복귀",
};

/**
 * 차수 변경 구분
 */
export enum RegistrationSequenceChangeHistoryType {
  IN = "IN",
  OUT = "OUT",
}
export const getRegistrationSequenceChangeHistoryTypeCodeName: any = {
  IN: "IN",
  OUT: "OUT",
};

/**
 * 강사 역할
 */
export enum TeacherRole {
  LECTURE = "LECTURE",
  TUTOR = "TUTOR",
}
export const getTeacherRoleCodename: any = {
  LECTURE: "강사",
  TUTOR: "튜터",
};

/**
 * 강사 구분
 */
export enum TeacherType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}
export const getTeacherTypeCodeName: any = {
  INTERNAL: "내부",
  EXTERNAL: "외부",
};

/**
 * 학습모임 상태
 */
export enum LearningLabStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  ENDED = "ENDED",
}
export const getLearningLabStatusCodeName: any = {
  REQUESTED: "신청",
  APPROVED: "승인",
  REJECTED: "반려",
  ENDED: "종료",
};

/**
 * 회의 구분
 */
export enum MeetingType {
  FORUM = "FORUM",
  SEMINAR = "SEMINAR",
}
export const getMeeingTypeCodeName: any = {
  FORUM: "포럼",
  SEMINAR: "세미나",
};

/**
 * 회의 상태
 */
export enum MeetingStatus {
  CREATED = "CREATED",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}
export const getMeetingStatusCodeName: any = {
  CREATED: "등록",
  APPROVED: "승인",
  CANCELLED: "취소",
};

/**
 * 차수 상태
 */
export enum SequenceStatus {
  APPLYING = "APPLYING",
  CANCELING = "CANCELING",
  SANCTIONING = "SANCTIONING",
  STUDYING = "STUDYING",
  RESTUDYING = "RESTUDYING",
  ENDED = "ENDED",
}
export const getSequenceStatusCodeName: any = {
  APPLYING: "신청중",
  CANCELING: "취소중",
  SANCTIONING: "결재중",
  STUDYING: "학습중",
  RESTUDYING: "재학습중",
  ENDED: "종료",
};

/**
 * 학습모임 구분
 */
export enum LearningLabType {
  GENERAL = "GENERAL",
  SEQUENCE = "SEQUENCE",
}
export const getLearningLabTypeCodeName: any = {
  GENERAL: "일반",
  SEQUENCE: "과정",
};

/**
 * 학습모임 맴버 유형
 */
export enum LearningLabMemberType {
  HOST = "HOST",
  GENERAL = "GENERAL",
  NEW = "NEW",
}
export const getLearningLabMemberTypeCodeName: any = {
  HOST: "방장",
  GENERAL: "일반",
  NEW: "신규",
};

/**
 * 학습모임 맴버 상태
 */
export enum LearningLabMemberStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  WITHDREW = "WITHDREW",
}
export const getLearningLabMemberStatusCodeName: any = {
  REQUESTED: "신청",
  APPROVED: "승인",
  REJECTED: "반려",
  WITHDREW: "탈퇴",
};

/**
 * 학습모임 검색 기간유형
 */
export enum LearningLabSearchPeriodType {
  ACTIVITY_START_DATE = "ACTIVITY_START_DATE",
  ACTIVITY_END_DATE = "ACTIVITY_END_DATE",
}
export const getLearningLabSearchPeriodTypeCodeName: any = {
  ACTIVITY_START_DATE: "활동기간시작일",
  ACTIVITY_END_DATE: "활동기간종료일",
};

/**
 * 회의 검색 기간유형
 */
export enum MeetingSearchPeriodType {
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  REGISTRATION_START_DATE = "REGISTRATION_START_DATE",
  REGISTRATION_END_DATE = "REGISTRATION_END_DATE",
}
export const getMeetingSearchPeriodTypeCodeName: any = {
  START_DATE: "시작일",
  END_DATE: "종료일",
  REGISTRATION_START_DATE: "신청시작일",
  REGISTRATION_END_DATE: "신청종료일",
};

/**
 * 플레이어 재생 위치 유형
 */
export enum PlayerPositionType {
  INITIALIZE = "INITIALIZE",
  LAST_POSITION = "LAST_POSITION",
  BOOKMARK = "BOOKMARK",
}

/**
 * 이전 페이지
 */
export enum PreviousPageCode {
  EXPLORE = "EXPLORE", // 탐색
  EXPLORE_DETAIL = "EXPLORE_DETAIL", // 탐색 상세
  STUDY_DASHBOARD = "STUDY_DASHBOARD", // 학습 대시보드
  STUDY_CONTENT = "STUDY_CONTENT", // 학습 콘텐츠
  SUBSCRIPTION_HOME = "SUBSCRIPTION_HOME", // 구독 홈
  CHANNEL = "CHANNEL", // 채널
  CHANNEL_PLAY_LIST = "CHANNEL_PLAY_LIST", // 채널 플레이리스트
  TOTAL_SEARCH = "TOTAL_SEARCH", // 통합검색
  MY_DASHBOARD = "MY_DASHBOARD", // MY 대시보드
  MY_FAVORITE = "MY_FAVORITE", // MY 찜관리
  MY_HISTORY = "MY_HISTORY", // MY 학습이력
}

/**
 * 페이지 모드
 */
export enum PageMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DETAIL = "DETAIL",
}

/**
 * 페이지당 갯수
 */
export const NumberOfPage = [20, 50, 100, 500];

/**
 * 알림스케쥴링 대분류
 */
export enum ScheduleMainCategory {
  "과정" = "SUBJECT",
  "학습모임" = "LEARNING_LAB",
  "세미나" = "SEMINAR",
}
export const getScheduleMainCategoryCodeName: any = {
  SUBJECT: "과정",
  LEARNING_LAB: "학습모임",
  SEMINAR: "세미나",
};

/**
 * 알림스케쥴링 중분류
 */
export enum ScheduleMiddleCategory {
  "공통" = "COMMON",
  "오프라인교육" = "OFFLINE_EDUCATION",
  "온라인교육" = "ONLINE_EDUCATION",
  "학습모임 일정" = "LEARNING_LAB_SCHEDULE",
  "세미나 활동" = "SEMINAR_LAB_ACTIVITY",
}
export const getScheduleMiddleCategoryCodeName: any = {
  COMMON: "공통",
  OFFLINE_EDUCATION: "오프라인교육",
  ONLINE_EDUCATION: "온라인교육",
  LEARNING_LAB_SCHEDULE: "학습모임 일정",
  SEMINAR_LAB_ACTIVITY: "세미나 활동",
};

/**
 * 알림스케쥴링 소분류
 */
export enum ScheduleSubCategory {
  "기타" = "OTHER",
  "학습관련" = "OFFLINE_LEARNING",
  "강사" = "OFFLINE_TEACHER",
  "학습독려" = "ONLINE_LEARNING_STIMULATION",
  "학습모임 일정알림" = "LEARNING_LAB_SCHEDULE_NOTICE",
  "세미나 일정알림" = "SEMINAR_SCHEDULE_NOTICE",
}
export const getScheduleSubCategoryCodeName: any = {
  OTHER: "기타",
  OFFLINE_LEARNING: "학습관련",
  OFFLINE_TEACHER: "강사",
  ONLINE_LEARNING_STIMULATION: "학습독려",
  LEARNING_LAB_SCHEDULE_NOTICE: "학습모임 일정알림",
  SEMINAR_SCHEDULE_NOTICE: "세미나 일정알림",
};

/**
 * 알림스케쥴링기준
 */
export enum ScheduleStandard {
  APPLICATION_START_DATE = "APPLICATION_START_DATE",
  APPLICATION_END_DATE = "APPLICATION_END_DATE",
  STUDY_START_DATE = "STUDY_START_DATE",
  STUDY_END_DATE = "STUDY_END_DATE",
  LEARNING_LAB_START_DATE = "LEARNING_LAB_START_DATE",
  LEARNING_LAB_END_DATE = "LEARNING_LAB_END_DATE",
}
export const getScheduleStandardCodeName: any = {
  APPLICATION_START_DATE: "수강신청시작일",
  APPLICATION_END_DATE: "수강신청종료일",
  STUDY_START_DATE: "학습시작일",
  STUDY_END_DATE: "학습종료일",
  LEARNING_LAB_START_DATE: "학습모임시작일",
  LEARNING_LAB_END_DATE: "학습모임종료일",
};

/**
 * 알림스케쥴링기준일유형
 */
export enum ScheduleStandardDayType {
  BEFORE = "BEFORE",
  IMMEDIATELY = "IMMEDIATELY",
  AFTER = "AFTER",
}
export const getScheduleStandardDayTypeCodeName: any = {
  BEFORE: "전",
  IMMEDIATELY: "즉시",
  AFTER: "후",
};

/**
 * 수료증번호규칙 세부규칙 구분
 */
export enum CertificateNumberRuleRulesType {
  CUSTOM_CODE = "CUSTOM_CODE",
  STUDY_DATE_YYYY = "STUDY_DATE_YYYY",
  STUDY_DATE_YYYYMM = "STUDY_DATE_YYYYMM",
  STUDY_END_DATE_YYYY_MM_DD = "STUDY_END_DATE_YYYY_MM_DD",
  ORDER_OF_COMPLETION = "ORDER_OF_COMPLETION",
}
export const getCertificateNumberRuleRulesTypeCodeName: any = {
  CUSTOM_CODE: "사용자코드",
  STUDY_DATE_YYYY: "학습연도",
  STUDY_DATE_YYYYMM: "학습연월(yyyymm)",
  STUDY_END_DATE_YYYY_MM_DD: "학습기간종료연월일(yyyy-mm-dd)",
  ORDER_OF_COMPLETION: "수료순서",
};

/**
 * 수료증 표시항목 구분
 */
export enum SequenceCertificateDisplayType {
  OFFLINE_COMMON = "OFFLINE_COMMON",
  OFFLINE_IRIS_LINK = "OFFLINE_IRIS_LINK",
  COMMON = "COMMON",
  COMMON_RESEARCH_NUMBER = "COMMON_RESEARCH_NUMBER",
  RESEARCH_ETHICS = "RESEARCH_ETHICS",
  RESEARCH_ETHICS_GRADUATE = "RESEARCH_ETHICS_GRADUATE",
  EQUIPMENT = "EQUIPMENT",
}
export const getSequenceCertificateDisplayTypeCodeName: any = {
  OFFLINE_COMMON: "[집합]공통",
  OFFLINE_IRIS_LINK: "[집합]연구윤리(연구자번호,유효기간)(교육부위탁사업)",
  COMMON: "[온라인]공통",
  COMMON_RESEARCH_NUMBER: "[온라인]공통(연구자번호)",
  RESEARCH_ETHICS: "[온라인]연구윤리",
  RESEARCH_ETHICS_GRADUATE: "[온라인]연구윤리(대학원생)",
  EQUIPMENT: "[온라인]장비",
};

/**
 * 참가증번호규칙 세부규칙 구분
 */
export enum CertificateParticipateNumberRuleRulesType {
  CUSTOM_CODE = "CUSTOM_CODE",
  PARTICIPATION_DATE_YYYY = "PARTICIPATION_DATE_YYYY",
  PARTICIPATION_DATE_YYYYMM = "PARTICIPATION_DATE_YYYYMM",
  PARTICIPATION_END_DATE_YYYY_MM_DD = "PARTICIPATION_END_DATE_YYYY_MM_DD",
  ORDER_OF_COMPLETION = "ORDER_OF_COMPLETION",
}
export const getCertificateParticipateNumberRuleRulesTypeCodeName: any = {
  CUSTOM_CODE: "사용자코드",
  PARTICIPATION_DATE_YYYY: "참가연도",
  PARTICIPATION_DATE_YYYYMM: "참가연월(yyyymm)",
  PARTICIPATION_END_DATE_YYYY_MM_DD: "참가종료연월일(yyyy-mm-dd)",
  ORDER_OF_COMPLETION: "참가순서",
};

/**
 * 참가증번호 세부타입
 */
export enum SequenceCertificateParticipateDetailType {
  LEARNING_LAB = "LEARNING_LAB",
  FORUM_AND_SEMINAR = "FORUM_AND_SEMINAR",
  CHANNEL_CONTENT = "CHANNEL_CONTENT",
}
export const getSequenceCertificateParticipateDetailTypeCodeName: any = {
  LEARNING_LAB: "학습모임",
  FORUM_AND_SEMINAR: "포럼/세미나",
  CHANNEL_CONTENT: "구독",
};

/**
 * 채널콘텐츠 타입
 */
export enum SequenceCertificateParticipateChannelContentsType {
  VIDEO_CONTENT = "VIDEO_CONTENT",
  AUDIO_CONTENT = "AUDIO_CONTENT",
  CARD_LEARNING_CONTENT = "CARD_LEARNING_CONTENT",
  WEB_VIDEO_CONTENT = "WEB_VIDEO_CONTENT",
  LINK_CONTENT = "LINK_CONTENT",
  PDF_CONTENT = "PDF_CONTENT",
  TEXT_CONTENT = "TEXT_CONTENT",
}
export const getSequenceCertificateParticipateChannelContentsTypeCodeName: any =
  {
    VIDEO_CONTENT: "동영상",
    AUDIO_CONTENT: "오디오",
    CARD_LEARNING_CONTENT: "카드러닝",
    WEB_VIDEO_CONTENT: "웹영상",
    LINK_CONTENT: "링크",
    PDF_CONTENT: "PDF",
    TEXT_CONTENT: "텍스트",
  };

/**
 * Live 메뉴 구분
 */
export enum LiveMenuType {
  LIVE_QUIZ = "LIVE_QUIZ",
  LIVE_POLL = "LIVE_POLL",
  LIVE_SURVEY = "LIVE_SURVEY",
  LIVE_QNA = "LIVE_QNA",
  LIVE_SHARE = "LIVE_SHARE",
}

/**
 * 게시판 보기 유형
 */
export enum BoardViewType {
  LIST = "LIST",
  ALBUM = "ALBUM",
}
export const getBoardViewTypeCodeName: any = {
  LIST: "목록형",
  ALBUM: "앨범형",
};

/**
 * 게시판 유형
 */
export enum BoardType {
  NORMAL = "NORMAL",
  NOTICE = "NOTICE",
  QNA = "QNA",
  FAQ = "FAQ",
}
export const getBoardTypeCodeName: any = {
  NORMAL: "일반",
  NOTICE: "공지",
  QNA: "Q&A",
  FAQ: "FAQ",
};

/**
 * 과거교육이력 결제방법
 */
export const getAsisEducationHistoryPaymentMethodCodeName: any = {
  무통장입금: "무통장입금",
  신용카드: "신용카드",
  개별계좌: "개별계좌",
};

/**
 * 과거교육이력 납입상태
 */
export const getAsisEducationHistoryPaymentStatusCodeName: any = {
  완납: "완납",
  미납: "미납",
  환불: "환불",
};

/**
 * 과거교육이력 입과상태
 */
export const getAsisEducationHistoryRegistrationStatusCodeName: any = {
  신청: "신청",
  대기: "대기",
  입과확정: "입과확정",
  취소요청: "취소요청",
  본인취소: "본인취소",
  운영자취소: "운영자취소",
};

export const getAsisGroupEducationHistoryRegistrationStatusCodeName: any = {
  대기: "대기",
  신청: "신청",
  본인취소: "본인취소",
  입과확정: "입과확정",
  취소요청: "취소요청",
  운영자취소: "운영자취소",
  기관담당자취소: "기관담당자취소",
};

/**
 * 과거교육이력 수료상태
 */
export const getAsisEducationHistoryCompleteStatusCodeName: any = {
  진행중: "진행중",
  수료: "수료",
  미수료: "미수료",
};

/**
 * 과거교육이력 검색 기간유형
 * EDUCATION_START_DATE : 교육시작일
 * EDUCATION_END_DATE   : 교육종료일
 */
export enum AsisEducationHistorySearchPeriodType {
  EDUCATION_START_DATE = "EDUCATION_START_DATE",
  EDUCATION_END_DATE = "EDUCATION_END_DATE",
}
export const getAsisEducationHistorySearchPeriodTypeCodeName: any = {
  EDUCATION_START_DATE: "교육시작일",
  EDUCATION_END_DATE: "교육종료일",
};

/**
 * 과거교육이력 소속구분
 */
export const getAsisEducationHistoryCompanyTypeCodeName: any = {
  기타: "기타",
  학계: "학계",
  산업계: "산업계",
  공공기관: "공공기관",
  소속없음: "소속없음",
  "출연(연)": "출연(연)",
  "정부/지자체": "정부/지자체",
};
export const getAsisOnline2017EducationHistoryCompanyTypeCodeName: any = {
  학계: "학계",
  공공기관: "공공기관",
  "출연(연)": "출연(연)",
};

/**
 * 답글 대상 유형
 */
export enum ReplyTargetType {
  ARTICLE = "ARTICLE",
}
export const getReplyTargetTypeCodeName: any = {
  ARTICLE: "게시물",
};

/**
 * (신규 API 전용) group로 코드 목록 조회
 * @param id
 */
export const selectCodeList = (group: string) => {
  return _codeStorage.filter((c) => c.group === group);
};

/**
 * (신규 API 전용) code id로 코드명 조회
 * @param id
 */
export const getCodeNameById = (id: number) => {
  return getCodeName(id);
};

/**
 * (Legacy API) MAJOR_CD + MINOR_CD 로 코드명 조회
 * @param code
 * @param group
 */
export const getCodeNameByCode = (code: string, group: string) => {
  const result = _codeStorage.find((c) => c.group === group && c.code === code);
  if (!!result) {
    return result.name;
  }
};

// 임시 아이디 중복 방지..
let hsIndex = 0;
/**
 * Local Storage ?? Cache ??
 */
const _codeStorage = [
  /** 학습모임 관련 시작 */
  { id: ++hsIndex, code: "GENERAL", group: "5000", name: "단독생성" },
  { id: ++hsIndex, code: "SEQUENCE", group: "5000", name: "과정연동" },
  /* 공지사항 분류 */
  { id: ++hsIndex, code: "system", group: "5001", name: "시스템" },
  { id: ++hsIndex, code: "study", group: "5001", name: "학습" },
  { id: ++hsIndex, code: "etc", group: "5001", name: "기타" },
  /* 검색 텍스트 분류구분 */
  { id: ++hsIndex, code: "제목", group: "5002", name: "제목" },
  { id: ++hsIndex, code: "작성자", group: "5002", name: "작성자" },
  { id: ++hsIndex, code: "내용", group: "5002", name: "내용" },
  /* 학습모임 메뉴타입 */
  { id: ++hsIndex, code: "구분", group: "5003", name: "구분" },
  { id: ++hsIndex, code: "게시판", group: "5003", name: "게시판" },
  { id: ++hsIndex, code: "링크", group: "5003", name: "링크" },
  /* 학습모임 멤버목록 */
  { id: ++hsIndex, code: "방장", group: "5004", name: "방장" },
  { id: ++hsIndex, code: "멤버", group: "5004", name: "멤버" },
  /* [사용자] 학습모임 검색 구분 */
  { id: ++hsIndex, code: "학습모임명", group: "5501", name: "학습모임명" },
  { id: ++hsIndex, code: "방장명", group: "5501", name: "방장명" },
  /** 학습모임 관련 끝 */
  /** 설정 관련 시작 */
  /* 배치 타입 */
  { id: ++hsIndex, code: "내부", group: "6001", name: "내부" },
  { id: ++hsIndex, code: "배치타입2", group: "6001", name: "배치타입2" },
  { id: ++hsIndex, code: "배치타입3", group: "6001", name: "배치타입3" },
  /* 배치 상태 */
  { id: ++hsIndex, code: "대기", group: "6002", name: "대기" },
  { id: ++hsIndex, code: "실행중", group: "6002", name: "실행중" },
  { id: ++hsIndex, code: "완료", group: "6002", name: "완료" },
  { id: ++hsIndex, code: "오류", group: "6002", name: "오류" },
  { id: ++hsIndex, code: "재실행", group: "6002", name: "재실행" },
  /* 알림스퀘줄 구분 */
  { id: ++hsIndex, code: "수강신청", group: "6003", name: "수강신청" },
  { id: ++hsIndex, code: "학습", group: "6003", name: "학습" },
  { id: ++hsIndex, code: "소셜러닝", group: "6003", name: "소셜러닝" },
  { id: ++hsIndex, code: "시설", group: "6003", name: "시설" },
  { id: ++hsIndex, code: "설문", group: "6003", name: "설문" },
  { id: ++hsIndex, code: "농작가", group: "6003", name: "농작가" },
  /* 알림스퀘줄 유형 */
  { id: ++hsIndex, code: "집합교육", group: "6004", name: "집합교육" },
  { id: ++hsIndex, code: "플립러닝", group: "6004", name: "플립러닝" },
  { id: ++hsIndex, code: "외부교육", group: "6004", name: "외부교육" },
  { id: ++hsIndex, code: "조합교육", group: "6004", name: "조합교육" },
  {
    id: ++hsIndex,
    code: "온라인(자체/포팅)",
    group: "6004",
    name: "온라인(자체/포팅)",
  },
  { id: ++hsIndex, code: "멘토링", group: "6004", name: "멘토링" },
  { id: ++hsIndex, code: "OJT", group: "6004", name: "OJT" },
  { id: ++hsIndex, code: "소셜러닝", group: "6004", name: "소셜러닝" },
  /* 알림스퀘줄 실행 타입 */
  { id: ++hsIndex, code: "any", group: "6005", name: "즉시" },
  { id: ++hsIndex, code: "before", group: "6005", name: "전" },
  { id: ++hsIndex, code: "after", group: "6005", name: "후" },
  /* 알림스퀘줄 플랫폼 */
  { id: ++hsIndex, code: "mail", group: "6006", name: "메일" },
  { id: ++hsIndex, code: "sms", group: "6006", name: "SMS" },
  /** 설정 관련 끝 */

  /** 회원관련 시작*/
  /** 강사회원 */
  /** 강사 등급 */
  { id: ++hsIndex, code: "1", group: "6101", name: "S" },
  { id: ++hsIndex, code: "2", group: "6101", name: "A" },
  { id: ++hsIndex, code: "3", group: "6101", name: "B" },
  { id: ++hsIndex, code: "4", group: "6101", name: "C" },
  { id: ++hsIndex, code: "5", group: "6101", name: "T" },
  /** 강사 등급 */
  { id: ++hsIndex, code: "1", group: "6102", name: "직급" },
  { id: ++hsIndex, code: "2", group: "6102", name: "학력" },
  { id: ++hsIndex, code: "3", group: "6102", name: "경력" },
  /** 추가지원 등급 */
  { id: ++hsIndex, code: "1", group: "6103", name: "1등급" },
  { id: ++hsIndex, code: "2", group: "6103", name: "2등급" },
  { id: ++hsIndex, code: "3", group: "6103", name: "3등급" },
  { id: ++hsIndex, code: "4", group: "6103", name: "4등급" },
  /** 청탁금지대상기관 */
  { id: ++hsIndex, code: "1", group: "6104", name: "공무원 및 공공기관" },
  { id: ++hsIndex, code: "2", group: "6104", name: "교직원 및 언론사" },
  /** 일반회원 */
  /** 직무분야 */
  { id: ++hsIndex, code: "1", group: "6105", name: "연구자" },
  { id: ++hsIndex, code: "2", group: "6105", name: "연구관리자" },
  { id: ++hsIndex, code: "3", group: "6105", name: "정책입안자" },
  { id: ++hsIndex, code: "4", group: "6105", name: "기타" },
  /** 전공 */
  { id: ++hsIndex, code: "1", group: "6106", name: "수학/물리/천문/지리" }, // MJ_1010
  { id: ++hsIndex, code: "2", group: "6106", name: "생명/화학/환경" }, // MJ_1030
  { id: ++hsIndex, code: "3", group: "6106", name: "기계/금속" }, // MJ_1060
  { id: ++hsIndex, code: "4", group: "6106", name: "재료/소재" }, // MJ_1070
  { id: ++hsIndex, code: "5", group: "6106", name: "화학공학" }, // MJ_1080
  { id: ++hsIndex, code: "6", group: "6106", name: "전기/전자" }, // MJ_1090
  { id: ++hsIndex, code: "7", group: "6106", name: "컴퓨터/통신" }, // MJ_1100
  { id: ++hsIndex, code: "8", group: "6106", name: "정밀/에너지" }, // MJ_1110
  { id: ++hsIndex, code: "9", group: "6106", name: "교통/운수" }, // MJ_1130
  { id: ++hsIndex, code: "10", group: "6106", name: "건축" }, // MJ_1140
  { id: ++hsIndex, code: "11", group: "6106", name: "토목/도시" }, // MJ_1020
  { id: ++hsIndex, code: "12", group: "6106", name: "농림/수산" }, // MJ_1040
  { id: ++hsIndex, code: "13", group: "6106", name: "의료" }, // MJ_1050
  { id: ++hsIndex, code: "14", group: "6106", name: "간호" }, // MJ_1200
  { id: ++hsIndex, code: "15", group: "6106", name: "약학" }, // MJ_1170
  { id: ++hsIndex, code: "16", group: "6106", name: "치료/보건" }, // MJ_1190
  { id: ++hsIndex, code: "17", group: "6106", name: "생활과학" }, // MJ_1120
  { id: ++hsIndex, code: "18", group: "6106", name: "기타" }, // MJ_1150
  { id: ++hsIndex, code: "19", group: "6106", name: "인문사회계열" }, // MJ_1210
  { id: ++hsIndex, code: "20", group: "6106", name: "예체능계열" }, // MJ_1220

  /** 경력 */
  { id: ++hsIndex, code: "1", group: "6107", name: "학사과정" }, // CR_1000
  { id: ++hsIndex, code: "2", group: "6107", name: "석사과정" }, // CR_1010
  { id: ++hsIndex, code: "3", group: "6107", name: "박사과정" }, // CR_1020
  { id: ++hsIndex, code: "4", group: "6107", name: "석박통합 3년 이내" }, // CR_1030
  { id: ++hsIndex, code: "5", group: "6107", name: "석박통합 3년 이상" }, // CR_1040
  { id: ++hsIndex, code: "6", group: "6107", name: "재직기간 5년 이내" }, // CR_1050
  { id: ++hsIndex, code: "7", group: "6107", name: "재직기간 5~10년" }, // CR_1060
  { id: ++hsIndex, code: "8", group: "6107", name: "재직기간 10~15년" }, // CR_1070
  { id: ++hsIndex, code: "9", group: "6107", name: "재직기간 15~20년" }, // CR_1080
  { id: ++hsIndex, code: "10", group: "6107", name: "재직기간 20년 이상" }, // CR_1090

  /** 직위/직급 */
  { id: ++hsIndex, code: "1", group: "6108", name: "원급" },
  { id: ++hsIndex, code: "2", group: "6108", name: "선임급" },
  { id: ++hsIndex, code: "3", group: "6108", name: "책임급 이상" },
  { id: ++hsIndex, code: "4", group: "6108", name: "사원/대리" },
  { id: ++hsIndex, code: "5", group: "6108", name: "과장/차장" },
  { id: ++hsIndex, code: "6", group: "6108", name: "부장 이상" },
  { id: ++hsIndex, code: "7", group: "6108", name: "대학원생" },
  { id: ++hsIndex, code: "8", group: "6108", name: "정교수" },
  { id: ++hsIndex, code: "9", group: "6108", name: "대학생" },
  { id: ++hsIndex, code: "10", group: "6108", name: "없음" },
  { id: ++hsIndex, code: "11", group: "6108", name: "조교수" },
  { id: ++hsIndex, code: "12", group: "6108", name: "부교수" },
  { id: ++hsIndex, code: "14", group: "6108", name: "기타" },

  /** K클럽 관심분야 */
  { id: ++hsIndex, code: "1", group: "6111", name: "연구노하우" }, // IR_1010
  { id: ++hsIndex, code: "2", group: "6111", name: "창업노하우" }, // IR_1050
  { id: ++hsIndex, code: "3", group: "6111", name: "진로상담" }, // IR_1030
  { id: ++hsIndex, code: "4", group: "6111", name: "취업정보" }, // IR_1040
  { id: ++hsIndex, code: "5", group: "6111", name: "경력전환" }, // IR_1020
  { id: ++hsIndex, code: "6", group: "6111", name: "인적 네트워크" }, // IR_1070
  { id: ++hsIndex, code: "7", group: "6111", name: "IT/테크 트렌드" }, // IR_1090

  /** K클럽 포스텍 학과 */
  { id: ++hsIndex, code: "1", group: "6112", name: "수학과" }, // POSU_1010
  { id: ++hsIndex, code: "2", group: "6112", name: "물리학과" }, // POSU_1020
  { id: ++hsIndex, code: "3", group: "6112", name: "화학과" }, // POSU_1030
  { id: ++hsIndex, code: "4", group: "6112", name: "생명과학과" }, // POSU_1040
  { id: ++hsIndex, code: "5", group: "6112", name: "신소재공학과" }, // POSU_1050
  { id: ++hsIndex, code: "6", group: "6112", name: "기계공학과" }, // POSU_1060
  { id: ++hsIndex, code: "7", group: "6112", name: "산업경영공학과" }, // POSU_1070
  { id: ++hsIndex, code: "8", group: "6112", name: "화학공학과" }, // POSU_1080
  { id: ++hsIndex, code: "9", group: "6112", name: "전자전기공학과" }, // POSU_1090
  { id: ++hsIndex, code: "10", group: "6112", name: "컴퓨터공학과" }, // POSU_1100
  { id: ++hsIndex, code: "11", group: "6112", name: "IT 융합공학과" }, // POSU_1110
  { id: ++hsIndex, code: "12", group: "6112", name: "환경공학부" }, // POSU_1120
  { id: ++hsIndex, code: "13", group: "6112", name: "인공지능대학원" }, // POSU_1130
  { id: ++hsIndex, code: "14", group: "6112", name: "첨단원자력공학부" }, // POSU_1140
  { id: ++hsIndex, code: "15", group: "6112", name: "융합생명공학부" }, // POSU_1150
  { id: ++hsIndex, code: "16", group: "6112", name: "첨단재료과학부" }, // POSU_1160
  { id: ++hsIndex, code: "17", group: "6112", name: "시스템생명공학과정" }, // POSU_1170
  { id: ++hsIndex, code: "18", group: "6112", name: "소셜데이터사이언스전공" }, // POSU_1180
  { id: ++hsIndex, code: "19", group: "6112", name: "철강·에너지소재대학원" }, // POSU_1190

  /** 기관 구분 */
  // BLNG_DIV_CD
  { id: ++hsIndex, code: "출연(연)", group: "6113", name: "출연(연)" },
  { id: ++hsIndex, code: "공공기관", group: "6113", name: "공공기관" },
  { id: ++hsIndex, code: "정부/지자체", group: "6113", name: "정부/지자체" },
  { id: ++hsIndex, code: "학계", group: "6113", name: "학계" },
  { id: ++hsIndex, code: "산업계", group: "6113", name: "산업계" },
  { id: ++hsIndex, code: "소속없음", group: "6113", name: "소속없음" },
  { id: ++hsIndex, code: "기타", group: "6113", name: "기타" },

  /** 이니시스 은행 코드 */
  { id: ++hsIndex, code: "02", group: "7000", name: "한국산업은행" },
  { id: ++hsIndex, code: "03", group: "7000", name: "기업은행" },
  { id: ++hsIndex, code: "04", group: "7000", name: "국민은행" },
  { id: ++hsIndex, code: "05", group: "7000", name: "하나은행 (구 외환)" },
  { id: ++hsIndex, code: "06", group: "7000", name: "국민은행 (구 주택)" },
  { id: ++hsIndex, code: "07", group: "7000", name: "수협중앙회" },
  { id: ++hsIndex, code: "11", group: "7000", name: "농협중앙회" },
  { id: ++hsIndex, code: "12", group: "7000", name: "단위농협" },
  { id: ++hsIndex, code: "16", group: "7000", name: "축협중앙회" },
  { id: ++hsIndex, code: "20", group: "7000", name: "우리은행" },
  { id: ++hsIndex, code: "21", group: "7000", name: "구)조흥은행" },
  { id: ++hsIndex, code: "22", group: "7000", name: "상업은행" },
  { id: ++hsIndex, code: "23", group: "7000", name: "SC제일은행" },
  { id: ++hsIndex, code: "24", group: "7000", name: "한일은행" },
  { id: ++hsIndex, code: "25", group: "7000", name: "서울은행" },
  { id: ++hsIndex, code: "26", group: "7000", name: "구)신한은행" },
  { id: ++hsIndex, code: "27", group: "7000", name: "한국씨티은행 (구 한미)" },
  { id: ++hsIndex, code: "31", group: "7000", name: "대구은행" },
  { id: ++hsIndex, code: "32", group: "7000", name: "부산은행" },
  { id: ++hsIndex, code: "34", group: "7000", name: "광주은행" },
  { id: ++hsIndex, code: "35", group: "7000", name: "제주은행" },
  { id: ++hsIndex, code: "37", group: "7000", name: "전북은행" },
  { id: ++hsIndex, code: "38", group: "7000", name: "강원은행" },
  { id: ++hsIndex, code: "39", group: "7000", name: "경남은행" },
  { id: ++hsIndex, code: "41", group: "7000", name: "비씨카드" },
  { id: ++hsIndex, code: "45", group: "7000", name: "새마을금고" },
  { id: ++hsIndex, code: "48", group: "7000", name: "신용협동조합중앙회" },
  { id: ++hsIndex, code: "50", group: "7000", name: "상호저축은행" },
  { id: ++hsIndex, code: "53", group: "7000", name: "한국씨티은행" },
  { id: ++hsIndex, code: "54", group: "7000", name: "홍콩상하이은행" },
  { id: ++hsIndex, code: "55", group: "7000", name: "도이치은행" },
  { id: ++hsIndex, code: "56", group: "7000", name: "ABN암로" },
  { id: ++hsIndex, code: "57", group: "7000", name: "JP모건" },
  { id: ++hsIndex, code: "59", group: "7000", name: "미쓰비시도쿄은행" },
  { id: ++hsIndex, code: "60", group: "7000", name: "BOA(Bank of America)" },
  { id: ++hsIndex, code: "64", group: "7000", name: "산림조합" },
  { id: ++hsIndex, code: "70", group: "7000", name: "신안상호저축은행" },
  { id: ++hsIndex, code: "71", group: "7000", name: "우체국" },
  { id: ++hsIndex, code: "81", group: "7000", name: "하나은행" },
  { id: ++hsIndex, code: "83", group: "7000", name: "평화은행" },
  { id: ++hsIndex, code: "87", group: "7000", name: "신세계" },
  { id: ++hsIndex, code: "88", group: "7000", name: "신한(통합)은행" },
  { id: ++hsIndex, code: "89", group: "7000", name: "케이뱅크" },
  { id: ++hsIndex, code: "90", group: "7000", name: "카카오뱅크" },
  {
    id: ++hsIndex,
    code: "91",
    group: "7000",
    name: "네이버포인트(포인트 100% 사용)",
  },
  { id: ++hsIndex, code: "92", group: "7000", name: "토스뱅크" },
  {
    id: ++hsIndex,
    code: "93",
    group: "7000",
    name: "토스머니(포인트100% 사용)",
  },
  {
    id: ++hsIndex,
    code: "94",
    group: "7000",
    name: "SSG머니(포인트 100% 사용)",
  },
  {
    id: ++hsIndex,
    code: "96",
    group: "7000",
    name: "엘포인트(포인트 100% 사용)",
  },
  { id: ++hsIndex, code: "97", group: "7000", name: "카카오 머니" },
  {
    id: ++hsIndex,
    code: "98",
    group: "7000",
    name: "페이코 (포인트 100% 사용)",
  },
  { id: ++hsIndex, code: "D1", group: "7000", name: "유안타증권(구 동양증권)" },
  { id: ++hsIndex, code: "D2", group: "7000", name: "현대증권" },
  { id: ++hsIndex, code: "D3", group: "7000", name: "미래에셋증권" },
  { id: ++hsIndex, code: "D4", group: "7000", name: "한국투자증권" },
  { id: ++hsIndex, code: "D5", group: "7000", name: "우리투자증권" },
  { id: ++hsIndex, code: "D6", group: "7000", name: "하이투자증권" },
  { id: ++hsIndex, code: "D7", group: "7000", name: "HMC투자증권" },
  { id: ++hsIndex, code: "D8", group: "7000", name: "SK증권" },
  { id: ++hsIndex, code: "D9", group: "7000", name: "대신증권" },
  { id: ++hsIndex, code: "DA", group: "7000", name: "하나대투증권" },
  { id: ++hsIndex, code: "DB", group: "7000", name: "굿모닝신한증권" },
  { id: ++hsIndex, code: "DC", group: "7000", name: "동부증권" },
  { id: ++hsIndex, code: "DD", group: "7000", name: "유진투자증권" },
  { id: ++hsIndex, code: "DE", group: "7000", name: "메리츠증권" },
  { id: ++hsIndex, code: "DF", group: "7000", name: "신영증권" },
  { id: ++hsIndex, code: "DG", group: "7000", name: "대우증권" },
  { id: ++hsIndex, code: "DH", group: "7000", name: "삼성증권" },
  { id: ++hsIndex, code: "DI", group: "7000", name: "교보증권" },
  { id: ++hsIndex, code: "DJ", group: "7000", name: "키움증권" },
  { id: ++hsIndex, code: "DK", group: "7000", name: "이트레이드" },
  { id: ++hsIndex, code: "DL", group: "7000", name: "솔로몬증권" },
  { id: ++hsIndex, code: "DM", group: "7000", name: "한화증권" },
  { id: ++hsIndex, code: "DN", group: "7000", name: "NH증권" },
  { id: ++hsIndex, code: "DO", group: "7000", name: "부국증권" },
  { id: ++hsIndex, code: "DP", group: "7000", name: "LIG증권" },
  { id: ++hsIndex, code: "BW", group: "7000", name: "뱅크월렛" },
];

/********** private Members ************/
const getCodeName = (id: number) => {
  const code = _codeStorage.find((c) => c.id === id);
  if (!!code) {
    return code.name;
  }
  /**
   * TODO DB에서 조회
   * 1. DB에서 조회
   * 2. Cache (or local storage) _codeStorage 에 추가
   * 3. return
   */
  return null;
};

/***** 코드 타입  *****/
export interface Code {
  id: number;
  code: string;
  group: string;
  name: string;
  icon?: IconType;
}

//삭제 금지 (boardId 고정 값들)
export const noticeBoardId = "f475b122-9a2e-43d3-b041-bd6103388b6a";
export const qnaBoardId = "f608710d-647f-45e6-b169-433f25883325";
export const faqBoardId = "42e65b5b-757e-457b-b908-a69f82b70ac2";
export const learningLabAllNoticeId = "1da757af-8e78-4550-8636-17eddc146df9";

export const getSeedHostName = () => {
  let result = "alpha-campus";
  const host = location.hostname;
  // const port = location.port;
  // if (port === "9090") {
  //     if (host.includes("localhost")) {
  //         result = "localhost";
  //     } else {
  //         result = "staging";
  //     }
  // } else {
  //     result = host.split(".")[0];
  // }
  result = host?.split(".")[0] ?? result;
  return result;
};

export type DiagnosisType = "BI" | "BG" | "DI" | "DG" | "";
export type DiagnosisCode =
  | "ABILITY_01"
  | "ABILITY_02"
  | "ABILITY_03"
  | "ABILITY_04"
  | "ABILITY_05"
  | "ABILITY_07"
  | "ABILITY_09"
  | "ABILITY_10"
  | "ABILITY_19"
  | "ABILITY_15"
  | "";

export interface AbilityCodeType {
  abilityName: string;
  abilityCode: string;
}

export interface KclubGroupCodeDataType {
  type: "AB01" | "AB02" | "AB03" | "AB04" | "AB05" | "AB06" | "AB07";
  code: DiagnosisCode;
  name: string;
  ability?: Array<AbilityCodeType>;
}

export interface KclubCodeType {
  dgnssType: DiagnosisType;
  name: string;
  groupCodeData: Array<KclubGroupCodeDataType>;
}

export const kclubCodeList: Array<KclubCodeType> = [
  {
    dgnssType: "BI",
    name: "기본형 재직자",
    groupCodeData: [
      {
        type: "AB01",
        code: "ABILITY_01",
        name: "R&D",
        ability: [
          { abilityCode: "ABILITY_01_01", abilityName: "기획력" },
          { abilityCode: "ABILITY_01_02", abilityName: "문제해결력" },
          { abilityCode: "ABILITY_01_03", abilityName: "의사소통" },
          { abilityCode: "ABILITY_01_04", abilityName: "연구설계" },
          { abilityCode: "ABILITY_01_05", abilityName: "연구수행" },
          { abilityCode: "ABILITY_01_06", abilityName: "성과창출" },
          { abilityCode: "ABILITY_01_07", abilityName: "연구필수" },
          { abilityCode: "ABILITY_01_08", abilityName: "과제실행관리" },
          { abilityCode: "ABILITY_01_09", abilityName: "과제성과관리" },
          { abilityCode: "ABILITY_01_10", abilityName: "과제평가" },
          { abilityCode: "ABILITY_01_11", abilityName: "사업실행관리" },
          { abilityCode: "ABILITY_01_11", abilityName: "사업성과관리" },
          { abilityCode: "ABILITY_01_12", abilityName: "사업평가" },
        ],
      },
      {
        type: "AB02",
        code: "ABILITY_02",
        name: "리더쉽",
        ability: [
          { abilityCode: "ABILITY_02_01", abilityName: "비전제시" },
          { abilityCode: "ABILITY_02_02", abilityName: "조직관리" },
          { abilityCode: "ABILITY_02_03", abilityName: "경영마인드" },
          { abilityCode: "ABILITY_02_04", abilityName: "임파워먼트" },
          { abilityCode: "ABILITY_02_05", abilityName: "목표관리" },
          { abilityCode: "ABILITY_02_06", abilityName: "조정통합" },
          { abilityCode: "ABILITY_02_07", abilityName: "변화관리" },
          { abilityCode: "ABILITY_02_08", abilityName: "성과지향" },
          { abilityCode: "ABILITY_02_09", abilityName: "팀워크지향" },
          { abilityCode: "ABILITY_02_10", abilityName: "자기개발" },
          { abilityCode: "ABILITY_02_11", abilityName: "조직이해" },
          { abilityCode: "ABILITY_02_12", abilityName: "주도적 업무추진" },
        ],
      },
      {
        type: "AB03",
        code: "ABILITY_03",
        name: "공통",
        ability: [
          { abilityCode: "ABILITY_03_01", abilityName: "사회적 가치 추구" },
          { abilityCode: "ABILITY_03_02", abilityName: "고객지향" },
          { abilityCode: "ABILITY_03_03", abilityName: "인지적 유연성" },
        ],
      },
    ],
  },
  {
    dgnssType: "BG",
    name: "기본형 대학원생",
    groupCodeData: [
      {
        type: "AB01",
        code: "ABILITY_01",
        name: "지식정보 기반문제 해결역량",
        ability: [
          { abilityCode: "ABILITY_01_01", abilityName: "분석적사고" },
          { abilityCode: "ABILITY_01_02", abilityName: "비판적사고" },
          { abilityCode: "ABILITY_01_03", abilityName: "창의적 사고" },
        ],
      },
      {
        type: "AB02",
        code: "ABILITY_02",
        name: "의사소통",
        ability: [
          { abilityCode: "ABILITY_02_01", abilityName: "주제이해" },
          { abilityCode: "ABILITY_02_02", abilityName: "토론 및 협의능력" },
          { abilityCode: "ABILITY_02_03", abilityName: "질의응답능력" },
          { abilityCode: "ABILITY_02_04", abilityName: "문서화능력" },
        ],
      },
      {
        type: "AB03",
        code: "ABILITY_03",
        name: "자기관리",
        ability: [
          { abilityCode: "ABILITY_03_01", abilityName: "자기주도적 학습" },
          { abilityCode: "ABILITY_03_02", abilityName: "정서조절 능력" },
          { abilityCode: "ABILITY_03_03", abilityName: "진로개발 능력" },
        ],
      },
      {
        type: "AB04",
        code: "ABILITY_04",
        name: "대인관계관리",
        ability: [
          { abilityCode: "ABILITY_04_01", abilityName: "네트워크 능력" },
          { abilityCode: "ABILITY_04_02", abilityName: "수용적 감성" },
          { abilityCode: "ABILITY_04_03", abilityName: "협업(팀워크능력)" },
        ],
      },
      {
        type: "AB05",
        code: "ABILITY_05",
        name: "연구실무",
        ability: [
          { abilityCode: "ABILITY_05_01", abilityName: "연구윤리" },
          { abilityCode: "ABILITY_05_02", abilityName: "프로젝트 기획력" },
          { abilityCode: "ABILITY_05_03", abilityName: "프로젝트 실행관리" },
          { abilityCode: "ABILITY_05_04", abilityName: "프로젝트 성과관리" },
        ],
      },
    ],
  },
  {
    dgnssType: "DI",
    name: "심화형 재직자",
    groupCodeData: [
      {
        type: "AB01",
        code: "ABILITY_01",
        name: "R&D",
        ability: [
          { abilityCode: "ABILITY_01_01", abilityName: "기획력" },
          { abilityCode: "ABILITY_01_02", abilityName: "문제해결력" },
          { abilityCode: "ABILITY_01_03", abilityName: "의사소통" },
          { abilityCode: "ABILITY_01_04", abilityName: "연구설계" },
          { abilityCode: "ABILITY_01_05", abilityName: "연구수행" },
          { abilityCode: "ABILITY_01_06", abilityName: "성과창출" },
          { abilityCode: "ABILITY_01_07", abilityName: "연구필수" },
          { abilityCode: "ABILITY_01_08", abilityName: "과제실행관리" },
          { abilityCode: "ABILITY_01_09", abilityName: "과제성과관리" },
          { abilityCode: "ABILITY_01_10", abilityName: "과제평가" },
          { abilityCode: "ABILITY_01_11", abilityName: "사업실행관리" },
          { abilityCode: "ABILITY_01_12", abilityName: "사업실행관리" },
          { abilityCode: "ABILITY_01_11", abilityName: "사업성과관리" },
          { abilityCode: "ABILITY_01_13", abilityName: "사업평가" },
          { abilityCode: "ABILITY_01_17", abilityName: "성과확산" },
          { abilityCode: "ABILITY_01_18", abilityName: "기획관리" },
        ],
      },
      {
        type: "AB02",
        code: "ABILITY_02",
        name: "리더쉽",
        ability: [
          { abilityCode: "ABILITY_02_01", abilityName: "비전제시" },
          { abilityCode: "ABILITY_02_02", abilityName: "조직관리" },
          { abilityCode: "ABILITY_02_03", abilityName: "경영마인드" },
          { abilityCode: "ABILITY_02_04", abilityName: "임파워먼트" },
          { abilityCode: "ABILITY_02_05", abilityName: "목표관리" },
          { abilityCode: "ABILITY_02_06", abilityName: "조정통합" },
          { abilityCode: "ABILITY_02_07", abilityName: "변화관리" },
          { abilityCode: "ABILITY_02_08", abilityName: "성과지향" },
          { abilityCode: "ABILITY_02_09", abilityName: "팀워크지향" },
          { abilityCode: "ABILITY_02_10", abilityName: "자기개발" },
          { abilityCode: "ABILITY_02_11", abilityName: "조직이해" },
          { abilityCode: "ABILITY_02_12", abilityName: "주도적 업무추진" },
        ],
      },
      {
        type: "AB03",
        code: "ABILITY_03",
        name: "공통",
        ability: [
          { abilityCode: "ABILITY_03_01", abilityName: "사회적가치추구" },
          { abilityCode: "ABILITY_03_02", abilityName: "고객지향" },
          { abilityCode: "ABILITY_03_03", abilityName: "인지적유연성" },
        ],
      },
    ],
  },
  {
    dgnssType: "DG",
    name: "심화형 대학원생",
    groupCodeData: [
      {
        type: "AB01",
        code: "ABILITY_01",
        name: "지식정보기반문제해결역량",
        ability: [
          { abilityCode: "ABILITY_01_01", abilityName: "분석적사고" },
          { abilityCode: "ABILITY_01_02", abilityName: "비판적사고" },
          { abilityCode: "ABILITY_01_03", abilityName: "창의적 사고" },
        ],
      },
      {
        type: "AB02",
        code: "ABILITY_02",
        name: "의사소통",
        ability: [
          { abilityCode: "ABILITY_02_01", abilityName: "주제이해" },
          { abilityCode: "ABILITY_02_02", abilityName: "토론 및 협의능력" },
          {
            abilityCode: "ABILITY_02_03",
            abilityName: "질의응답능력 (전달과 표현 역량)",
          },
          { abilityCode: "ABILITY_02_04", abilityName: "문서화능력" },
        ],
      },
      {
        type: "AB03",
        code: "ABILITY_03",
        name: "자기관리",
        ability: [
          { abilityCode: "ABILITY_03_01", abilityName: "자기주도적 학습" },
          { abilityCode: "ABILITY_03_02", abilityName: "정서조절 능력" },
          { abilityCode: "ABILITY_03_03", abilityName: "진로개발 능력" },
        ],
      },
      {
        type: "AB04",
        code: "ABILITY_04",
        name: "대인관계관리",
        ability: [
          { abilityCode: "ABILITY_04_01", abilityName: "네트워크 능력" },
          { abilityCode: "ABILITY_04_02", abilityName: "수용적 감성" },
          { abilityCode: "ABILITY_04_03", abilityName: "협업(팀워크능력)" },
        ],
      },
      {
        type: "AB05",
        code: "ABILITY_05",
        name: "연구실무",
        ability: [
          { abilityCode: "ABILITY_05_01", abilityName: "연구윤리" },
          { abilityCode: "ABILITY_05_02", abilityName: "프로젝트 기획력" },
          { abilityCode: "ABILITY_05_03", abilityName: "프로젝트 실행관리" },
          { abilityCode: "ABILITY_05_04", abilityName: "프로젝트 성과관리" },
        ],
      },
      {
        type: "AB06",
        code: "ABILITY_07",
        name: "과학기술인유형",
        ability: [
          { abilityCode: "ABILITY_07_01", abilityName: "성취욕구" },
          { abilityCode: "ABILITY_07_02", abilityName: "목표" },
          { abilityCode: "ABILITY_07_03", abilityName: "인지양식" },
        ],
      },
      {
        type: "AB07",
        code: "ABILITY_09",
        name: "사회적바람직성",
        ability: [
          { abilityCode: "ABILITY_09_01", abilityName: "사회적바람직성" },
        ],
      },
    ],
  },
];

/**
 * 쿠키 설정
 */
export const objCookies = {
  get: ({ name }: { name: string }) => {
    const cookies = parseCookies();

    if (cookies[name] !== undefined) {
      const parseCookie = JSON.parse(cookies[name]);
      return typeof parseCookie === "string" ? [parseCookie] : parseCookie;
    } else {
      return undefined;
    }
  },
  addWeek: ({
    path,
    name,
    value,
  }: {
    path?: string;
    name: string;
    value: any;
  }) => {
    const cookies = parseCookies();
    if (path === undefined) path = "/";

    let newValues = new Array<string>();
    if (cookies[name] !== undefined) {
      const parseCookie = JSON.parse(cookies[name]);
      newValues = typeof parseCookie === "string" ? [parseCookie] : parseCookie;
    }

    newValues.push(value);
    setCookie(null, name, JSON.stringify(newValues), {
      maxAge: 7 * 24 * 60 * 60,
      path: path,
    });
  },
};

// 하루용
export const objCookiesDay = {
  get: ({ name }: { name: string }) => {
    const cookies = parseCookies();

    if (cookies[name] !== undefined) {
      const parseCookie = JSON.parse(cookies[name]);
      return typeof parseCookie === "string" ? [parseCookie] : parseCookie;
    } else {
      return undefined;
    }
  },
  addDay: ({
    path,
    name,
    value,
  }: {
    path?: string;
    name: string;
    value: any;
  }) => {
    const cookies = parseCookies();
    if (path === undefined) path = "/";

    let newValues = new Array<string>();
    if (cookies[name] !== undefined) {
      const parseCookie = JSON.parse(cookies[name]);
      newValues = typeof parseCookie === "string" ? [parseCookie] : parseCookie;
    }

    newValues.push(value);
    setCookie(null, name, JSON.stringify(newValues), {
      maxAge: 24 * 60 * 60, // 1 day in seconds
      path: path,
    });
  },
};

export function formatFloat(number) {
  if (number == null) return null;
  return +parseFloat(number).toFixed(3);
}

// NOTE: lsh 숫자 천원단위 콤마 찍기
export function addCommasToNumber(data: number, spacing?: boolean) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spacing ? ", " : ",");
}

// NOTE: lsh 연간교육 일정표에 사용되는 classification 정렬 기준
export const classificationOrder = {
  "출연(연) 및 공공연구기관 기본": 0,
  "중소기업 R&D 실무역량": 1,
  "대학(원)생 R&D 경력개발": 2,
  "정책입안/연구관리직 R&D 사업기획·관리 교육": 3,
  "R&D 전문": 4,
  "국가전략기술": 5,
  "R&D 법령·규정": 6,
  "과학기술인 소양": 7,
  "과학기술 트렌드": 8,
  "법정필수": 9
};

export const classificationDepthOrder = [
  {
    firstDepth: "출연(연) 및 공공연구기관 기본",
    secondDepthOrder: {
      "출연(연) 및 공공연구기관  R&D 리더십": 0,
      "출연(연) 및 공공연구기관 직급별 교육": 1,
      "출연(연) 및 공공연구기관 고경력자": 2,
    }
  },
  {
    firstDepth: "중소기업 R&D 실무역량",
    secondDepthOrder: {
      "중소기업 국가 R&D 참여(희망)자": 0,
      "중소기업 기술분야 재직자": 1,
    }
  },
  {
    firstDepth: "대학(원)생 R&D 경력개발",
    secondDepthOrder: {
      "이공계 대학(원)생": 0,
      "박사후연구원": 1,
      "외국인 유학생": 2,
      "이공계 장애 대학(원)생": 3,
    }
  },
  {
    firstDepth: "정책입안/연구관리직 R&D 사업기획·관리 교육",
    secondDepthOrder: {
      "과학기술정보통신부 공무원": 0,
      "지역과학기술인": 1,
    }
  },
  {
    firstDepth: "R&D 전문",
    secondDepthOrder: {
      "연구개발 역량": 0,
      "연구행정 역량": 1,
      "R&D 전문 공통": 2,
    }
  },
  {
    firstDepth: "국가전략기술",
    secondDepthOrder: {
      "인공지능": 0,
      "우주": 1,
      "반도체·디스플레이": 2,
      "사이버보안": 3,
      "양자": 4,
      "첨단바이오": 5,
      "차세대 원자력": 6,
      "이차전지": 7,
      "첨단 모빌리티": 8,
    }
  },
  {
    firstDepth: "R&D 법령·규정",
    secondDepthOrder: {
      "국가연구개발혁신법": 0,
      "연구윤리": 1,
      "연구보안": 2,
      "연구비 관리": 3,
      "연구실 안전": 4,
    }
  },
  {
    firstDepth: "과학기술인 소양",
    secondDepthOrder: {
      "과학기술인 소양": 0,
    }
  },
  {
    firstDepth: "과학기술 트렌드",
    secondDepthOrder: {
      "과학기술 트렌드": 0,
    }
  },
  {
    firstDepth: "법정필수",
    secondDepthOrder: {
      "법정필수": 0,
    }
  },
];



//NOTE: lsh 증빙서류 타입
export enum EvidenceDocumentType {
  EDUCATION_PAYMENT_CONFIRMATION = "EDUCATION_PAYMENT_CONFIRMATION",
  GENERATE_CONFIRMATION = "GENERATE_CONFIRMATION",
  TRANSACTION_STATEMENT = "TRANSACTION_STATEMENT",
  CARD_RECEIPT = "CARD_RECEIPT",
}
export const getEvidenceDocumentTypeCodeName: any = {
  EDUCATION_PAYMENT_CONFIRMATION: "교육비 납부 확인서",
  GENERATE_CONFIRMATION: "입과 확인서",
  TRANSACTION_STATEMENT: "거래명세서",
  CARD_RECEIPT: "카드영수증",
};

//NOTE: lsh 날짜 데이터로 요일 구하기
export const getDayOfWeek = (dateString: string, ko?: boolean) => {
  const date = new Date(dateString);
  const daysOfWeek = ko
    ? ["일", "월", "화", "수", "목", "금", "토"]
    : [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
  return daysOfWeek[date.getDay()];
};

/**
 * 차차수 출석 상태
 */
export enum SequenceModuleAttendanceStatus {
  ATTENDANCE = "ATTENDANCE",
  NOT_ATTENDANCE = "NOT_ATTENDANCE",
}
export const getSequenceModuleAttendanceStatus: any = {
  ATTENDANCE: "출석",
  NOT_ATTENDANCE: "결석",
};

/**
 * 과정운영 - 과정조회 탭 query 조회를 위한 타입 선언
 */
export interface SortProp {
  name: string;
  key: "" | "desc" | "asc";
}

export interface SearchOperationTabFormikProps {
  pageSize: number;
  pageNumber: number;

  sortParam: SortProp[];
}

/**
 * 과정운영 - 입과관리 납부상태 변경 시 얼럿 메시지 타입 선언
 */
export const ChangeRegistrationStatus: { [key: number]: string } = {
  0: "아래 학습자가 변경됩니다.",
  1: "본 과정은 무료 과정입니다.",
  2: "정원이 초과될 수도 있습니다. 그래도 변경하시겠습니까?",
  3: "학습자의 입과 상태값을 확인해 주세요",
  4: "결제방법이 전자계산서(후불)로 처리됩니다. 그래도 변경하시겠습니까?",
  5: "정원이 초과될 수도 있으며, 결제방법이 전자계산서(후불)로 처리됩니다. 그래도 변경하시겠습니까?",
  6: "기존 결제가 취소 또는 환불 처리됩니다. 그래도 변경하시겠습니까?",
  7: "예외케이스가 발생했습니다. KIRD로 문의주세요",
};

interface ChangeRegistrationStatusType {
  isFree: boolean;
  defaultStatus: RegistrationStatus;
  selectedStatus: RegistrationStatus;
  paymentMethod?: string;
  paymentStatus?: string;
}

export const getChangeRegistrationStatus = ({
  isFree,
  defaultStatus,
  selectedStatus,
  paymentMethod,
  paymentStatus,
}: ChangeRegistrationStatusType): number => {
  console.log(
    isFree,
    defaultStatus,
    selectedStatus,
    paymentMethod,
    paymentStatus
  );

  // NOTE: lsh 모든 케이스에서 취소/반려의 경우
  if (defaultStatus == RegistrationStatus.CANCELLED || defaultStatus == RegistrationStatus.REJECTED) return 3;

  if (isFree) {
    if (selectedStatus == RegistrationStatus.WAITING_PAYMENT) {
      // NOTE: lsh 무료과정에서 결제대기로 변경할 경우는 전부 1 리턴
      return 1;
    } else if (selectedStatus == RegistrationStatus.CANCELLED || selectedStatus === RegistrationStatus.REJECTED) {
      // NOTE: lsh 무료과정에서 취소/반려로 갈 경우는 0리턴 (기존 입과상태가 취소/반려 인 경우는 3리턴 상위에서 선처리됨)
      return 0;
    }

    if (defaultStatus == RegistrationStatus.WAITING) {
      switch (selectedStatus) {
        case RegistrationStatus.APPROVED:
          return 2;
      }
    } else if (defaultStatus == RegistrationStatus.NOT_COMPLETED) {
      switch (selectedStatus) {
        case RegistrationStatus.APPROVED:
          return 3;
      }
    } else if (defaultStatus == RegistrationStatus.APPROVED) {
      switch (selectedStatus) {
        case RegistrationStatus.APPROVED:
          return 3;
      }
    } else if (defaultStatus == RegistrationStatus.REQUESTED) {
      switch (selectedStatus) {
        case RegistrationStatus.APPROVED:
          return 0;
      }
    }
  } else {
    if (defaultStatus == RegistrationStatus.WAITING_PAYMENT) {
      if (paymentMethod == PaymentMethod.NOT_DECIDED) {
        switch (selectedStatus) {
          case RegistrationStatus.WAITING_PAYMENT:
            return 3;
          case RegistrationStatus.APPROVED:
            return 4;
          case RegistrationStatus.CANCELLED:
          case RegistrationStatus.REJECTED:
            return 0;
        }
      } else if (paymentMethod == PaymentMethod.VIRTUAL_ACCOUNT) {
        switch (selectedStatus) {
          case RegistrationStatus.WAITING_PAYMENT:
            return 6;
          case RegistrationStatus.APPROVED:
            return 4;
          case RegistrationStatus.CANCELLED:
          case RegistrationStatus.REJECTED:
            return 0;
        }
      } else if (paymentMethod == PaymentMethod.TAX_BILL) {
        if (paymentStatus == PaymentStatus.NON_PAYMENT) {
          switch (selectedStatus) {
            case RegistrationStatus.APPROVED:
              return 4;
            case RegistrationStatus.WAITING_PAYMENT:
            case RegistrationStatus.CANCELLED:
            case RegistrationStatus.REJECTED:
              return 6;
          }
        } else if (paymentStatus == PaymentStatus.FULL_PAYMENT) {
          switch (selectedStatus) {
            case RegistrationStatus.WAITING_PAYMENT:
              return 3;
            case RegistrationStatus.APPROVED:
            case RegistrationStatus.CANCELLED:
            case RegistrationStatus.REJECTED:
              return 6;
          }
        } else if (paymentStatus == PaymentStatus.REFUND) {
          switch (selectedStatus) {
            case RegistrationStatus.WAITING_PAYMENT:
              return 3;
            case RegistrationStatus.APPROVED:
              return 4;
            case RegistrationStatus.CANCELLED:
            case RegistrationStatus.REJECTED:
              return 6;
          }
        }
      }

    } else if (defaultStatus == RegistrationStatus.WAITING) {
      switch (selectedStatus) {
        case RegistrationStatus.WAITING_PAYMENT:
          return 2;
        case RegistrationStatus.APPROVED:
          return 5;
        case RegistrationStatus.CANCELLED:
        case RegistrationStatus.REJECTED:
          return 0;
      }
    } else if (defaultStatus == RegistrationStatus.NOT_COMPLETED) {
      switch (selectedStatus) {
        case RegistrationStatus.WAITING_PAYMENT:
          return 6;
        case RegistrationStatus.APPROVED:
        case RegistrationStatus.CANCELLED:
        case RegistrationStatus.REJECTED:
          return 3;
      }
    } else if (defaultStatus == RegistrationStatus.APPROVED) {
      switch (selectedStatus) {
        case RegistrationStatus.APPROVED:
          return 3;
        case RegistrationStatus.WAITING_PAYMENT:
        case RegistrationStatus.CANCELLED:
        case RegistrationStatus.REJECTED:
          return 6;
      }
    }  else if (defaultStatus == RegistrationStatus.REQUESTED) {
      switch (selectedStatus) {
        case RegistrationStatus.APPROVED:
          return 4;
        case RegistrationStatus.WAITING_PAYMENT:
        case RegistrationStatus.CANCELLED:
        case RegistrationStatus.REJECTED:
          return 0;
      }
    }
  }
  return 7;
};

/**
 * NOTE: lsh 숫자를 한글로 바꿔주는 함수
 */
export function numberToKorean(num: number): string {
  const arr1 = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
  const arr2 = ["", "십", "백", "천"];
  const arr3 = ["", "만", "억", "조"];
  const splitUnit = 10_000;
  const resultArray: string[] = [];

  if (num === 0) return "영";

  let unitPos = 0;

  while (num > 0) {
    let unit = num % splitUnit;
    let subResult = "";
    let pos = 0;

    while (unit > 0) {
      const digit = unit % 10;
      if (digit > 0) {
        subResult = arr1[digit] + arr2[pos] + subResult;
      }
      unit = Math.floor(unit / 10);
      pos++;
    }

    if (subResult !== "") {
      subResult += arr3[unitPos];
    }

    resultArray.unshift(subResult);
    num = Math.floor(num / splitUnit);
    unitPos++;
  }

  return resultArray.join("");
}

/**
 * NOTE: lsh 뎁스 객체
 */
export const classificationDepth = [
  { value: "0", name: "전체" },
  { value: "1", name: "1차분류" },
  { value: "2", name: "2차분류" },
  { value: "3", name: "3차분류" },
];

/**
 * NOTE: lsh 과정상세에서 상단의 반려, 승인 하단의 삭제버튼 사용가능한 권한 리스트
 */
export const SUBJECT_APPROVE_ADMIN_ROLE_LIST = [
  "COMPANY_GROUP_MANAGER_ADMIN", // 주관처관리자
  "SYSTEM_MANAGER_ADMIN", // 시스템 관리자
  "ADMIN", // 총괄관리자
  "LEARNING_SUPPORT_MANAGER_ADMIN", // 학십지원센터 관리자
  "OFFLINE_MANAGER_ADMIN", // 집합과정 관리자
  "ONLINE_MANAGER_ADMIN", // 온라인과정 관리자
];

/**
 * NOTE 신청 가능 여부 (신청가능/대기가능/신청마감)
 */
export const SUBJECT_APPLY_TYPE ={
  APPLY:"신청가능",
  WAITING:"대기가능",
  IMPOSSIBLE:"신청마감"
}

/**
 * NOTE: lsh 과정운영 - 과정상세에서 탭 미노출 권한 리스트
 */
export const SEQUENCE_TAP_DISABLED_ADMIN_ROLE_LIST = [
  "COMPANY_MANAGER_ADMIN", // 기관관리자
];

/**
 * NOTE: lsh 회원 상세 조회시 수정페이지로 진입가능한 권한 목록
 */
export const MEMBER_EDITABLE_ADMIN_ROLE_LIST = [
  "ADMIN", // 총괄관리자
  "SYSTEM_MANAGER_ADMIN", // 시스템 관리자
  "LEARNING_SUPPORT_MANAGER_ADMIN", // 학십지원센터 관리자
];

/**
 * NOTE: lsh lrs 데이터를 실서버와 분리하기위해 확인
 */
export const BASE_DOMAIN = "alpha-campus.kr"